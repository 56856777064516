import React from "react";
import styles from "./CardCollectionLearnMoreModal.module.scss";
import classNames from "classnames";
import image from "@wisetack/assets/img/info.svg";

const CardCollectionLearnMoreModal = ({loanExpirationDate}) => {
  return (
    <div
      className={classNames("modal fade", styles.modal)}
      id="consumerCardCollectionLearnMoreModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`consumerCardCollectionLearnMoreModal`}
      aria-hidden="false"
    >
      <div
        className={classNames("modal-dialog", styles.modalDialog)}
        role="document"
      >
        <div className={classNames("modal-content", styles.modalContent)}>
          <div className={classNames("modal-header", styles.modalHeader)}>
            <img className={styles.image} src={image} alt="info-logo" />
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className={classNames("modal-body", styles.modalBody)} style={{textAlign: "center"}}>
            <h4>Adding a debit card</h4>
            <p>
              We require the first payment
              <br/>when your loan starts.
            </p>
            <p>
              We will use the debit card provided
              <br/>to withdraw funds for the first
              <br/>installment only.
            </p>
            <p>
              We may place a $1 temporary
              <br/>authorization on your card to verify
              <br/>eligibility.
            </p>
            <p>
              If you don’t complete this step by {loanExpirationDate}, your application will expire.
            </p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default CardCollectionLearnMoreModal;
