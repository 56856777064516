import moment from "moment";
import React, {Fragment} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Log from "@wisetack/shared-ui/utils/Log";
import {formatAmount, formatAPR} from "@wisetack/shared-ui/utils/format";
import Error from "@wisetack/shared-ui/components/Error";
import {LoaderWithMessage} from "../components/LoaderWithMessage";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import classNames from "classnames";
import styles from "./ConsumerReviewPlanPage.module.scss";
import image from "@wisetack/assets/img/contract.svg";
import {
  acceptTruthInLending,
  getOffers,
  getTila,
  sendDocumentCopy,
  setError,
  submitCheckboxSelection
} from "../store/actions/consumerActions";
import PdfModalZoom from "@wisetack/shared-ui/components/PdfModalZoom";
import LoanAgreementModal from "@wisetack/shared-ui/components/LoanAgreementModal";
import CreditScoreModal from "@wisetack/shared-ui/components/CreditScoreModal";
import ssnImage from "@wisetack/assets/img/ssn-icon-2@3x.png";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {BorrowerFieldValidator} from "@wisetack/shared-ui/utils/BorrowerFieldValidator";
import {postIframeEvent} from "@wisetack/shared-ui/src/utils/iframeEventProducer";

const pageName = "TIL Page";

class ConsumerReviewPlanPage extends React.Component {

  state = {
    reviewed: this.props.confirmed,
    ssn4: "",
    errors: {}
  };

  constructor(props) {
    super(props);
    this.validator = new BorrowerFieldValidator(pageName)
  }

  isPayoutsListFound() {
    return this.props.payouts && this.props.payouts.length > 1
  }

  navigateToPayouts() {
    if (this.isPayoutsListFound()) {
      this.props.history.push("/loan_payouts");
      return true;
    }
    return false;
  }

  isSelectedPlanFound() {
    if (!this.props.selectedLoanOfferId) {
      return false
    }
    if (!this.props.selectedPlan) {
      return false
    }
    return Object.keys(this.props.selectedPlan).length > 0
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.loanAppId) {
      this.props.history.push("/error");
      this.props.setError('Please use your payment link to log in.')
      return
    }
    if (this.navigateToPayouts()) {
      return
    }
    if (!this.isSelectedPlanFound()) {
      // selectedLoanOfferId can be unknown here as request to get loan application data can still be running
      // so instead to wait for selectedLoanOfferId we will get all loan offers in parallel
      this.props.getOffers(this.props.loanAppId)
    }
    this.logProps = {
      loanId: this.props.loanAppId.substr(0, 8),
      merchantName: this.props.merchantName,
      page: pageName,
      emailVerificationRequired: !!this.props.emailVerificationRequired
    }
    logAmplitudeEvent(pageName, this.logProps);
    this.validator.props = this.logProps;
  }

  componentDidUpdate(prevProps) {
    if (this.navigateToPayouts()) {
      return
    }
    if (this.props.initExpired) {
      Log.info(this.props.initExpired, `initExpired`);
      this.props.history.push("/expired");
      return;
    }
    if (this.props.declineReason) {
      Log.info(this.props.declineReason, `declineReason`);
      if (this.props.declineReason === 'INELIGIBLE_FOR_ADDITIONAL_LOAN') {
        this.props.history.push("/declined_max");
      } else {
        this.props.history.push("/declined");
      }
      return;
    }
    if (this.props.errorMessage) {
      Log.info(this.props.errorMessage, `error`);
      this.props.history.push("/error");
      return;
    }
    if (this.props.ssn4Error) {
      Log.info(this.props.ssn4Error, `ssn4error...`);
      if (prevProps.ssn4Error !== this.props.ssn4Error)
        this.setState({
          errors: {
            ...this.state.errors,
            ssn4: this.props.ssn4Error
          }
        });
    }
    // add logic here to route to another page depending from status
    switch (this.props.selectedLoanOfferStatus) {
      case "TILA_ACCEPTED":
      case "CONVERTED":
        if (this.props.emailVerificationRequired === true) {
          this.props.history.push("/email_confirm");
          break;
        }
        if (this.props.serviceCompletionDate && moment(this.props.serviceCompletionDate).isAfter(moment(), 'day')) {
          this.props.history.push("/purchase_confirm_pending");
        } else {
          this.props.history.push("/purchase_confirm");
        }
        break;
      default:
        break;
    }
  }

  handleOnButtonClick = () => {
    const plan = this.props.selectedPlan || {};
    logAmplitudeEvent("Pressed Accept Terms and Sign Button", {
      ...this.logProps,
      offerSelectedDuration: plan.months,
      selectedInterestFreeOffer: plan.apr === 0,
      emailVerificationRequired: !!this.props.emailVerificationRequired
    });
    this.props.acceptTruthInLending(
      this.props.loanAppId,
      this.props.selectedLoanOfferId,
      this.state.ssn4,
      this.state.reviewed
    );
    postIframeEvent({
      event: 'LOAN_TERMS_ACCEPTED',
      approvedLoanAmount: formatAmount(this.props.selectedPlan.approvedAmount)
    });
  };

  handleOnEnterPress = event => {
    event.preventDefault();
    if (this.state.reviewed && event.key === 'Enter') {
      this.handleOnButtonClick();
    } 
  }

  validateField = (name, val) => {
    switch (name) {
      case "ssn4":
        return this.validator.validateHiddenStartSSN(val);
      default:
        return "";
    }
  };


  handleOnChange = e => {
    let val = e.target.value;
    let name = e.target.name;

    if (name === "ssn4") {
      val = val.replace(/\D/g, "");

      if (val.length > 4) {
        return;
      }
      val = "XXX-XX-" + val;
    }

    this.setState({ [name]: val });
    this.setError(name, "");
  };

  handleOnBlur = e => {
    let val = e.target.value;
    let name = e.target.name;
    const error = this.validateField(name, val);
    this.setError(name, error);
  };

  setError(name, error) {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: error
      }
    });
  };

  canGoToOffers = () => {
    let res = this.props.status === "ACCOUNT_LINKED"
        || this.props.status === "CONDITIONAL_APPROVAL"
        || this.props.status === "CONDITIONAL_APPROVAL_ACCOUNT_LINKED"
        || this.props.status === "CONDITIONAL_APPROVAL_DEBIT_ADDED"
        || this.props.status === "OFFER_AVAILABLE";
    //res= false;
    return res;
  }

  handleOnBackToOfferClick = () => {
    logAmplitudeEvent("Pressed Back to offers Button", this.logProps);
    if(this.canGoToOffers()) {
      this.props.history.push("/choose_plan");
      return;
    }
    return;
  };



  render() {
    Log.info(this.props, `ConsumerReviewPlanPage props`);

    const plan = this.props.selectedPlan || {};

    let btnDisabled =
      !this.state.reviewed || !this.props.checkboxes['ALMOST_DONE_PAGE'] || Object.keys(plan).length === 0 ||
      !this.state.ssn4;

    for (const key in this.state.errors) {
      if (this.state.errors[key]) btnDisabled = true;
    }

    const btnClasses = classNames({
      btn: true,
      "btn-block": true,
      "btn-disabled": btnDisabled,
      [styles.buttonDisabled]: btnDisabled,
      [styles.buttonEnabled]: !btnDisabled
    });

    const showContent = !this.props.errorMessage && !this.props.isLoading;

    const loadTila = () => {
      return new Promise((resolve, reject) => {
          if (!this.props.loanAppId || !this.props.selectedLoanOfferId) {
            reject()
            return;
          }
          getTila(this.props.loanAppId, this.props.selectedLoanOfferId)
          .then( res => {
            if (res && res.loanOfferDetailsList) {
              resolve(atob(res.loanOfferDetailsList[0].truthInLending));
            }
          })
          .catch(err => reject(err));
      });
    }

    const btnBackClasses = classNames({
      [styles.buttonBack]: true
    });


    const backToOffersButton = () => {
      if(!this.canGoToOffers()){
        return null;
      }

      return <div className="col">
          <div
              data-test-id="back to offers"
              className={btnBackClasses}
              onClick={this.handleOnBackToOfferClick}
          >
            BACK TO OFFERS
          </div>
        </div>

    }

    const conditionalHeader = () => {
      if (this.canGoToOffers())
        return <PageHeader progress="71%" onBack={() => this.handleOnBackToOfferClick()}>
          {showContent ? <Fragment>Review your terms</Fragment> : null}
          {null}
        </PageHeader>
      return <PageHeader progress="71%">
        {showContent ? <Fragment>Review your terms</Fragment> : null}
        {null}
      </PageHeader>
    }

    const firstMonthPrepayment = this.props.firstMonthPrepayment;
    return (
       <Container>
         { this.isSelectedPlanFound() && !this.isPayoutsListFound() &&
           <PdfModalZoom name="truthInLendingModal" dataLoadFunc={loadTila}
                         allowCopySend={true} applicationID={this.props.loanAppId} documentID={this.props.truthInLendingID} prefill={this.props.email} sendFunction={sendDocumentCopy}
                         headerTitle="Truth In Lending" />
         }
        <LoanAgreementModal />
        <CreditScoreModal />
         {conditionalHeader()}
        <LoaderWithMessage isLoading={this.props.isLoading} />
        <Error pageName={pageName}>{this.props.errorMessage}</Error>
        {showContent ? (
          <>
            <div className={styles.truth}>
              <img className={styles.image} src={image} alt="bank-logo" />
              <div className={styles.title}>LOAN SUMMARY</div>
              <div className={styles.items}>
                <div className={styles.item}>
                  Financed Amount:{" "}
                  <span>{formatAmount(plan.approvedAmount)}</span>
                </div>
                <div className={styles.item}>
                  Number of Payments: <span>{plan.numberOfPayments}</span>
                </div>
                <div className={styles.item}>
                  Amount of Each Payment:{" "}
                  <span>{formatAmount(plan.amount)}</span>
                </div>
                <div className={styles.item}>
                  Due Monthly{firstMonthPrepayment ? " After Loan Confirmation" : ""}
                </div>
                <div className={styles.item}>
                  &nbsp;&nbsp;&nbsp;&nbsp;<i>Estimated Starting:{" "}</i>
                  <span><i>{plan.starting}</i></span>
                </div>
                <div className={styles.item}>
                  Total Finance Charges:{" "}
                  <span>{formatAmount(plan.interest)}</span>
                </div>
                <div className={styles.item}>
                  Sum of All Payments:{" "}
                  <span>{formatAmount(plan.totalPayments)}</span>
                </div>
              </div>
              <div className={styles.line} />
              <div className={classNames("row", styles.title)}>
                <div className={styles.annualPercentageText}>
                  Annual Percentage Rate
                </div>
                <div
                  className={styles.annualPercentageValue}
                  style={{ textAlign: "right" }}
                >
                  {formatAPR(plan.apr)}
                </div>
              </div>
              <div className={styles.annualPercentageTextLabel}>
                (The cost of your credit as a yearly rate)
              </div>
            </div>
            <div className={styles.approve}>
              <div className="row">
                <div className="col-1" style={{ minWidth: "40px" }}>
                  <div
                    data-test-id="reviewed"
                    role="dialog"
                    className={styles.box}
                    onClick={() => {
                      const rev = !this.state.reviewed;
                      this.props.submitCheckboxSelection(this.props.loanAppId, "ALMOST_DONE_PAGE", rev, { loanOfferId: this.props.selectedLoanOfferId });
                      this.setState({ reviewed: rev });
                    }
                    }
                  >
                    {this.state.reviewed && (
                      <span
                        className="material-icons"
                        style={{
                          fontSize: "25px",
                          paddingLeft: "1px",
                          paddingTop: "1px"
                        }}
                      >
                        done
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className={classNames("col", styles.reviewed)}
                  style={{ lineHeight: 1.5 }}
                >
                  I have reviewed and agree to the
                  <span
                    data-toggle="modal"
                    data-target="#truthInLendingModal"
                    onClick={() => {
                      logAmplitudeEvent("Opened TIL link", this.logProps);
                    }}
                  >
                    &nbsp;Truth in Lending Disclosure
                  </span>
                  &nbsp;and{" "}
                  <span
                    data-toggle="modal"
                    data-target="#loanAgreement"
                    onClick={() => {
                      logAmplitudeEvent("Opened Loan agreement link", this.logProps);
                    }}
                  >
                    &nbsp;Loan Agreement
                  </span>
                  , and have received the
                  <span
                    data-toggle="modal"
                    data-target="#creditScoreModal"
                    onClick={() => {
                      logAmplitudeEvent("Opened Credit Score disclosure link", this.logProps);
                    }}
                  >
                    &nbsp;Credit Score Disclosure.
                  </span>
                </div>
              </div>
            </div>
            <div style={{ margin: "15px 0px 15px 15px", paddingRight: "25px" }}>
              <Form>
                <FormRow>
                  <div className="input-group-prepend">
                    <img className={styles.ssn} src={ssnImage} alt="bank-logo" />
                  </div>
                  <FormInput
                    inputMode="numeric"
                    pattern="[0-9]*"
                    name="ssn4"
                    label="Enter Last 4 Digits of SSN to Sign"
                    value={this.state.ssn4}
                    onFocus={() => {
                      if (this.state.ssn4 === "") {
                        this.setState({ ssn4: "XXX-XX-" });
                      }
                    }}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnBlur}
                    errors={this.state.errors}
                  />
                </FormRow>
              </Form>
            </div>
            <div
              className="row"
              style={{
                paddingBottom: "5px",
                paddingLeft: "15px",
                paddingRight: "15px"
              }}
            >
              <div className="col">
                <button
                  data-test-id="confirm"
                  className={btnClasses}
                  onClick={this.handleOnButtonClick}
                  onKeyPress={this.handleOnEnterPress}
                >
                  <span
                    className="material-icons"
                    style={{
                      fontSize: "16px",
                      marginRight: "5px",
                      paddingBottom: "3px"
                    }}
                  >
                    lock_outline
                  </span>
                  {"ACCEPT TERMS & SIGN"}
                </button>
              </div>
              { false &&  backToOffersButton() }
            </div>

            <div className={styles.message}>
              <span>Release funds in the next step</span>
              <span className={classNames("material-icons", styles.next_icon)}>arrow_forward</span>
            </div>
          </>
        ) : (
            <div style={{ paddingBottom: "15px" }} />
          )}
      </Container>
    );
  }
}

ConsumerReviewPlanPage.propTypes = {
  confirmed: PropTypes.bool,
  selectedPlan: PropTypes.object,
  acceptTruthInLending: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  selectedLoanOfferId: state.consumer.selectedLoanOfferId,
  truthInLending: state.consumer.truthInLending,
  truthInLendingID: state.consumer.truthInLendingID,
  isLoading: state.consumer.isLoading,
  errorMessage: state.consumer.errorMessage,
  ssn4Error: state.consumer.ssn4Error,
  status: state.consumer.status,
  selectedLoanOfferStatus: state.consumer.selectedLoanOfferStatus,
  selectedPlan: state.consumer.selectedPlan,
  acceptedTruthInLendingId: state.consumer.acceptedTruthInLendingId,
  truthInLendingAcceptedAt: state.consumer.truthInLendingAcceptedAt,
  initExpired: state.consumer.initExpired,
  email: state.consumer.email,
  emailVerificationRequired: state.consumer.emailVerificationRequired,
  serviceCompletionDate: state.consumer.serviceCompletionDate,
  declineReason: state.consumer.declineReason,
  checkboxes: state.consumer.checkboxes,
  vertical: state.consumer.vertical,
  payouts: state.consumer.payouts,
  firstMonthPrepayment: state.consumer.firstMonthPrepayment
});

export default connect(
  mapStateToProps,
  { getOffers, acceptTruthInLending, submitCheckboxSelection, setError}
)(ConsumerReviewPlanPage);
