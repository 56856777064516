import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import CardCollectionForm from "./CardCollectionForm";
import {LoaderWithMessage} from "../../components/LoaderWithMessage";
import {loadToken} from "@wisetack/shared-ui/utils/localStorage";
import {getLoanStatus} from "../../store/actions/consumerActions";
import {
    CARD_COLLECTED_MESSAGE,
    CARD_COLLECTION_BROADCAST_CHANNEL,
    CLOSE_POPUPS_MESSAGE,
    FMP_ON_ANSWER_MESSAGE,
    FMP_ON_QUESTION_MESSAGE,
    FMP_ON_QUESTION_TIMEOUT,
    POPUP_OPENED_MESSAGE
} from "./CardCollectionConstants";
import Log from "@wisetack/shared-ui/utils/Log";

class CardCollectionPopUp extends React.Component {

    broadcastChannel;

    constructor(props) {
        super(props);

        this.setUpBroadcastChannel();

        setTimeout(() => {
            this.setState({popupTimeout: true});
        }, FMP_ON_QUESTION_TIMEOUT);
        this.broadcastChannel.postMessage(FMP_ON_QUESTION_MESSAGE);
        this.broadcastChannel.postMessage(POPUP_OPENED_MESSAGE);
    }

    componentDidMount = () => {
        this.props.getLoanStatus(loadToken());
    }

    componentDidUpdate = (prevProps) => {
        if (this.state && this.state.popupTimeout) {
            this.handleEntry(prevProps);
        }
    }

    setUpBroadcastChannel = () => {
        this.broadcastChannel = new BroadcastChannel(CARD_COLLECTION_BROADCAST_CHANNEL);

        this.broadcastChannel.postMessage(CLOSE_POPUPS_MESSAGE);

        this.broadcastChannel.onmessage = (event) => {
            if (event.data === CLOSE_POPUPS_MESSAGE) {
                window.close();
            } else if (event.data && event.data.name === FMP_ON_ANSWER_MESSAGE) {
                this.setState({
                    firstMonthPrepayment: true,
                    loanAppExpirationDate: event.data.loanAppExpirationDate
                });
            }
        };
    }

    handleEntry = (prevProps) => {

        const cardCollected = this.props.status === "CONDITIONAL_APPROVAL_DEBIT_ADDED" ||
            (prevProps.status === "CONDITIONAL_APPROVAL" && this.props.status === "OFFER_AVAILABLE");

        if (!this.state.firstMonthPrepayment) {
            this.props.history.push("/error");
        } else if (this.props.initExpired) {
            Log.info(this.props.initExpired, `initExpired`);
            this.props.history.push("/expired");
        } else if (cardCollected) {
            this.broadcastChannel.postMessage(CARD_COLLECTED_MESSAGE);
            window.close();
        } else if (this.props.status === "CONDITIONAL_APPROVAL") {
            //stay here!
        } else {
            this.props.history.push("/error");
        }

    }

    render = () => {

        const {
            status,
            isLoading,
            expectedRunLength
        } = this.props;

        let showContent = !isLoading && status &&
            (this.state && this.state.firstMonthPrepayment && this.state.loanAppExpirationDate);

        return (
            <>
                <Container>
                    <PageHeader progress="40%">
                        {showContent &&
                            <Fragment>
                                <div>
                                    Add a debit card
                                </div>
                            </Fragment>
                        }
                        {showContent &&
                            <Fragment>
                                <div>
                                    {/*PageHeader needs a 2nd child to show the title*/}
                                </div>
                            </Fragment>
                        }
                    </PageHeader>
                    <LoaderWithMessage isLoading={!showContent} duration={expectedRunLength}/>
                    {status && this.state && this.state.firstMonthPrepayment && this.state.loanAppExpirationDate
                        && <CardCollectionForm loanExpirationDate={this.state.loanAppExpirationDate}/>}
                </Container>
            </>
        );
    }
}

CardCollectionPopUp.propTypes = {
    getLoanStatus: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => ({
    loanAppId: state.consumer.loanAppId,
    isLoading: state.consumer.isLoading,
    status: state.consumer.status
});

export default connect(mapStateToProps, {getLoanStatus})(
    CardCollectionPopUp
);
