"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateZipCode = exports.validateWebsite = exports.validateStreetAddress = exports.validateState = exports.validateScheduledCompletionDate = exports.validateSafeTextLastName = exports.validateSafeTextFirstName = exports.validateSSN4 = exports.validateSSN = exports.validateRoutingNumber = exports.validateMobileNumberInDomesticFormat = exports.validateMobileNumber = exports.validateLastName = exports.validateIndustryFreeText = exports.validateIndustry = exports.validateHiddenStartSSN = exports.validateFirstName = exports.validateEmail = exports.validateEIN = exports.validateDoingBusinessAs = exports.validateDateOfBirth = exports.validateCompletedOn = exports.validateCity = exports.validateCardExpirationDate = exports.validateBusinessLegalName = exports.validateAccountNumber = exports.logValidationError = exports.Regex = void 0;

var _moment = _interopRequireDefault(require("moment"));

var _tldjs = require("tldjs");

var _Amplitude = require("../components/Amplitude");

var _Messages = require("./Messages");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var OK = "";
var Regex = {
  PHONE_REGEX: /^\d+$/,
  EMAIL_REGEX: /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/i,
  STATE_REGEX: /^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$/,
  ZIP_CODE_REGEX: /(^\d{5}$)/,
  ROUTING_NUMBER_REGEX: /(^\d{9}$)/,
  EIN_REGEX: /(^\d{9}$)/,
  SSN_REGEX: /(^\d{9}$)/,
  SSN4_REGEX: /(^\d{4}$)/,
  ACCOUNT_NUMBER_REGEX: /(^\d{4,17}$)/,
  WEBSITE_REGEX: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\/)?$/,
  CLEAN_ASCII_REGEX: /^[ -~]*$/,
  SAFE_TEXT_REGEX: /^[a-zA-Z0-9\s/'"&#=:;,.+_!%)(|-]+$/,
  SAFE_TEXT_REGEX_REPLACE: /[a-zA-Z0-9\s/'"&#=:;,.+_!%)(|-]/g
};
exports.Regex = Regex;

var logValidationError = function logValidationError(validateFn, application, page, field) {
  var props = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  var suggestFn = arguments.length > 5 ? arguments[5] : undefined;
  return function () {
    var values = null;
    var suggestCallback = null;

    for (var _len = arguments.length, val = new Array(_len), _key = 0; _key < _len; _key++) {
      val[_key] = arguments[_key];
    }

    if (val.length > 1 && typeof val[val.length - 1] === "function") {
      values = val.slice(0, val.length - 1);
      suggestCallback = val[val.length - 1];
    } else {
      values = val;
    }

    var message = validateFn.apply(void 0, _toConsumableArray(values));

    if (message && message.length > 0) {
      props = _objectSpread(_objectSpread({}, props), {}, {
        application: application,
        page: page,
        field: field,
        message: message
      });
      (0, _Amplitude.logAmplitudeEvent)("Validation Error", props);
    }

    if (suggestCallback && suggestFn) {
      suggestFn(values, suggestCallback);
    }

    return message;
  };
};

exports.logValidationError = logValidationError;

var removeAllDuplicates = function removeAllDuplicates() {
  var _String$prototype;

  var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return (_String$prototype = String.prototype).concat.apply(_String$prototype, _toConsumableArray(new Set(str)));
};

var validateFirstName = function validateFirstName(value) {
  if (!value) return _Messages.Messages.ENTER_FIRST_NAME;
  if (value.length < 2) return _Messages.Messages.TWO_LETTERS_MINIMUM;
  return OK;
};

exports.validateFirstName = validateFirstName;

var validateLastName = function validateLastName(value) {
  if (!value) return _Messages.Messages.ENTER_LAST_NAME;
  if (value.length < 2) return _Messages.Messages.TWO_LETTERS_MINIMUM;
  return OK;
};

exports.validateLastName = validateLastName;

var validateSafeTextFirstName = function validateSafeTextFirstName(value) {
  if (!value) return _Messages.Messages.ENTER_FIRST_NAME;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return _Messages.Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  return OK;
};

exports.validateSafeTextFirstName = validateSafeTextFirstName;

var validateSafeTextLastName = function validateSafeTextLastName(value) {
  if (!value) return _Messages.Messages.ENTER_LAST_NAME;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return _Messages.Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  return OK;
};

exports.validateSafeTextLastName = validateSafeTextLastName;

var validateEmail = function validateEmail(value) {
  if (!value) return _Messages.Messages.ENTER_EMAIL;
  if (!Regex.EMAIL_REGEX.test(value)) return _Messages.Messages.ENTER_VALID_EMAIL;
  if (!(0, _tldjs.tldExists)(value)) return _Messages.Messages.ENTER_VALID_TLD;
  return OK;
};

exports.validateEmail = validateEmail;

var validateWebsite = function validateWebsite(value) {
  if (!value) {
    return OK;
  }

  if (!value.match(new RegExp(Regex.WEBSITE_REGEX))) {
    return _Messages.Messages.ENTER_VALID_WEBSITE;
  }

  return OK;
};

exports.validateWebsite = validateWebsite;

var validateMobileNumber = function validateMobileNumber(value) {
  if (!value) return _Messages.Messages.ENTER_PHONE;
  var mobileNumber = value.replace(/-/g, "");
  if (!Regex.PHONE_REGEX.test(mobileNumber)) return _Messages.Messages.ENTER_VALID_PHONE;
  if (mobileNumber.length === 10) return OK;
  if (mobileNumber.length === 11 && mobileNumber[0] === "1") return OK;
  return _Messages.Messages.ENTER_VALID_PHONE_ALT;
};

exports.validateMobileNumber = validateMobileNumber;

var validateMobileNumberInDomesticFormat = function validateMobileNumberInDomesticFormat(value) {
  if (!value) return _Messages.Messages.ENTER_PHONE;
  var mobileNumber = value.replace(/\D/g, "");
  if (!Regex.PHONE_REGEX.test(mobileNumber)) return _Messages.Messages.ENTER_VALID_PHONE;
  if (mobileNumber.length === 10) return OK;
  if (mobileNumber.length === 11 && mobileNumber[0] === "1") return OK;
  return _Messages.Messages.ENTER_VALID_PHONE_ALT;
};

exports.validateMobileNumberInDomesticFormat = validateMobileNumberInDomesticFormat;

var validateState = function validateState(value) {
  if (!value) return _Messages.Messages.ENTER_STATE;
  if (!Regex.STATE_REGEX.test(value)) return _Messages.Messages.ENTER_VALID_STATE;
  return OK;
};

exports.validateState = validateState;

var validateZipCode = function validateZipCode(value) {
  if (!value) return _Messages.Messages.ENTER_ZIP_CODE;
  if (!Regex.ZIP_CODE_REGEX.test(value)) return _Messages.Messages.ENTER_ZIP_CODE;
  return "";
};

exports.validateZipCode = validateZipCode;

var validateEIN = function validateEIN(value) {
  if (!value) return _Messages.Messages.ENTER_EIN;
  var EIN = value.replace(/-/g, "");
  if (!Regex.EIN_REGEX.test(EIN)) return _Messages.Messages.ENTER_EIN;
  return "";
};

exports.validateEIN = validateEIN;

var validateDateOfBirth = function validateDateOfBirth(month, day, year) {
  if (!month || !day || !year) return _Messages.Messages.ENTER_DOB;
  if (month.length !== 2 || day.length !== 2 || year.length !== 4) return _Messages.Messages.INVALID_DATE_FORMAT;
  if (!(0, _moment["default"])("".concat(month, "/").concat(day, "/").concat(year), "MM/DD/YYYY").isValid()) return _Messages.Messages.INVALID_DATE;
  if ((0, _moment["default"])("".concat(month, "/").concat(day, "/").concat(year), "MM/DD/YYYY").isSameOrAfter((0, _moment["default"])().subtract(1, "days"))) return _Messages.Messages.INVALID_FUTURE_DATE;
  if ((0, _moment["default"])("".concat(month, "/").concat(day, "/").concat(year), "MM/DD/YYYY").isSameOrBefore((0, _moment["default"])().subtract(120, "years"))) return _Messages.Messages.INVALID_PAST_DATE;
  return OK;
};

exports.validateDateOfBirth = validateDateOfBirth;

var validateCardExpirationDate = function validateCardExpirationDate(month, year, twoDigitYear) {
  if (!month || !year) return _Messages.Messages.ENTER_CARD_EXPIRATION_DATE;
  var errorMessage = twoDigitYear ? _Messages.Messages.INVALID_CARD_SHORT_EXPIRATION_DATE_FORMAT : _Messages.Messages.INVALID_CARD_EXPIRATION_DATE_FORMAT;
  var yearLength = twoDigitYear ? 2 : 4;
  var yearFormat = twoDigitYear ? "MM/YY" : "MM/YYYY";
  if (month.length !== 2 || year.length !== yearLength) return errorMessage;
  if (!(0, _moment["default"])("".concat(month, "/").concat(year), yearFormat).isValid()) return _Messages.Messages.INVALID_DATE;
  return OK;
};

exports.validateCardExpirationDate = validateCardExpirationDate;

var validateScheduledCompletionDate = function validateScheduledCompletionDate(date, loanAppExpirationDate) {
  if (!(0, _moment["default"])(date).isValid()) return OK;
  if (!(0, _moment["default"])(date).isSameOrAfter((0, _moment["default"])())) return 'INVALID_PAST_SCHEDULED_COMPLETION_DATE';
  if ((0, _moment["default"])(date).isSameOrAfter((0, _moment["default"])(loanAppExpirationDate))) return 'INVALID_PAST_TO_OFFER_COMPLETION_DATE';
  return OK;
};

exports.validateScheduledCompletionDate = validateScheduledCompletionDate;

var validateCompletedOn = function validateCompletedOn(month, day, year) {
  if (!month || !day || !year) return _Messages.Messages.ENTER_VALID_DATE;
  if (month.length !== 2 || day.length !== 2 || year.length !== 4) return _Messages.Messages.INVALID_DATE_FORMAT;
  if (!(0, _moment["default"])("".concat(month, "/").concat(day, "/").concat(year), "MM/DD/YYYY").isValid()) return _Messages.Messages.INVALID_DATE;
  return OK;
};

exports.validateCompletedOn = validateCompletedOn;

var validateSSN = function validateSSN(value) {
  if (!value) return _Messages.Messages.ENTER_SSN;
  if (!Regex.SSN_REGEX.test(value.replace(/\D/g, ""))) return _Messages.Messages.ENTER_VALID_SSN;
  return OK;
};

exports.validateSSN = validateSSN;

var validateSSN4 = function validateSSN4(value) {
  if (!value || value.length !== 4) return _Messages.Messages.ENTER_SSN4;
  if (!Regex.SSN4_REGEX.test(value)) return _Messages.Messages.ENTER_SSN4;
  if (value === '0000') return _Messages.Messages.INVALID_SSN4;
  return OK;
};

exports.validateSSN4 = validateSSN4;

var validateHiddenStartSSN = function validateHiddenStartSSN(value) {
  value = value.replace("XXX-XX-", "");
  if (!value || value.length !== 4) return _Messages.Messages.ENTER_SSN4_HIDDEN;
  if (!Regex.SSN4_REGEX.test(value)) return _Messages.Messages.ENTER_VALID_SSN4;
  return OK;
};

exports.validateHiddenStartSSN = validateHiddenStartSSN;

var validateRoutingNumber = function validateRoutingNumber(value) {
  if (!value) return _Messages.Messages.ENTER_ROUTING_NUMBER;
  if (!Regex.ROUTING_NUMBER_REGEX.test(value)) return _Messages.Messages.ENTER_ROUTING_NUMBER;
  return "";
};

exports.validateRoutingNumber = validateRoutingNumber;

var validateAccountNumber = function validateAccountNumber(value) {
  if (!value) return _Messages.Messages.ENTER_ACCOUNT_NUMBER;
  if (!Regex.ACCOUNT_NUMBER_REGEX.test(value)) return _Messages.Messages.ENTER_ACCOUNT_NUMBER;
  return "";
};

exports.validateAccountNumber = validateAccountNumber;

var validateBusinessLegalName = function validateBusinessLegalName(value) {
  if (!value) return _Messages.Messages.ENTER_BUSINESS_LEGAL_NAME;
  if (value.length > 120) return _Messages.Messages.MAX_LENGTH_120_EXCEEDED;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return _Messages.Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  return "";
};

exports.validateBusinessLegalName = validateBusinessLegalName;

var validateDoingBusinessAs = function validateDoingBusinessAs(value) {
  if (!value) return "";
  if (value.length > 120) return _Messages.Messages.MAX_LENGTH_120_EXCEEDED;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return _Messages.Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  if (value.trimStart().trimEnd().length < 3) return _Messages.Messages.INVALID_DOING_BUSINESS_AS;
  return "";
};

exports.validateDoingBusinessAs = validateDoingBusinessAs;

var validateStreetAddress = function validateStreetAddress(value) {
  if (!value) return _Messages.Messages.ENTER_ADDRESS;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return _Messages.Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  return "";
};

exports.validateStreetAddress = validateStreetAddress;

var validateCity = function validateCity(value) {
  if (!value) return _Messages.Messages.ENTER_CITY;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return _Messages.Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  return "";
};

exports.validateCity = validateCity;

var validateIndustryFreeText = function validateIndustryFreeText(value) {
  if (!value || !value.trim()) return _Messages.Messages.ENTER_INDUSTRY_FREE_TEXT;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return _Messages.Messages.ENTER_INDUSTRY_FREE_TEXT;
  return "";
};

exports.validateIndustryFreeText = validateIndustryFreeText;

var validateIndustry = function validateIndustry(value) {
  if (!value) return _Messages.Messages.ENTER_INDUSTRY;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return _Messages.Messages.ENTER_INDUSTRY;
  return "";
};

exports.validateIndustry = validateIndustry;