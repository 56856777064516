import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import uuid from "uuid";
import Log from "@wisetack/shared-ui/utils/Log";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import PlaidLink from "@wisetack/shared-ui/components/PlaidLink";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import ConsumerLinkBankReadMoreModal from "@wisetack/shared-ui/components/ConsumerLinkBankLearnMoreModal";
import ConsumerVerifyBankReadMoreModal from "@wisetack/shared-ui/components/ConsumerVerifyBankLearnMoreModal";
import PaymentAuthModal from "@wisetack/shared-ui/components/PaymentAuthModal";
import styles from "./ConsumerLinkBankPage.module.scss";
import image from "@wisetack/assets/img/piggy-bank.svg";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";

import {acceptPlaidToken, createLinkToken, getLoanStatus} from "../store/actions/consumerActions";
import warnImage from "@wisetack/assets/img/warning-icon.svg";
import Buttons from "@wisetack/shared-ui/components/Buttons";

function pushCurrentState(event) {
  window.history.pushState(null, document.title, window.location.href);
}

class ConsumerLinkBankPage extends React.Component {

  state = {
    errorMessage: null,
    linkToken: null       // for OAuth

  }

  isPayoutsListFound() {
    return this.props.payouts && this.props.payouts.length > 1
  }

  componentDidMount() {

    const pageName = this.props.bankVerificationRequired ? 'Bank Link Required Page' : 'Autopay Page (bank link not required)'
    window.scrollTo(0, 0);

    if(this.props.autoPaymentsDecision){
      let page = this.afterSkip();
      if(page){
        this.props.history.push(page);
        return;
      }
    }

    this.logProps = {
      loanId: this.props.loanAppId.substr(0, 8),
      merchantName: this.props.merchantName,
      page: pageName
    }
    logAmplitudeEvent(pageName, this.logProps);

    if (this.props.location && this.props.location.pathname === '/link_bank_oauth') {
      this.getOAuthData();
      if(!localStorage.getItem('oauth_href')) {
        this.props.history.push("/link_bank");
        return;
      }
    }
    if (this.props.location && this.props.location.pathname === '/link_bank' && !this.props.linkTokens[this.props.loanAppId]) {
      this.props.createLinkToken(this.props.loanAppId, uuid.v4());
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.bankVerificationRequired && this.props.errorMessage) {
      logAmplitudeEvent("Bank verification error", {
        ...this.logProps,
        errorMessage: this.props.errorMessage
      });
    }
    if (this.props.initExpired) {
      Log.info(this.props.initExpired, `initExpired`);
      this.props.history.push("/expired");
    }
    if (this.props.acceptPlaidTokenRequestId && this.props.acceptPlaidTokenRequestId !== prevProps.acceptPlaidTokenRequestId) {
      if (this.props.status === "CONDITIONAL_APPROVAL_ACCOUNT_LINKED") {
        if (this.props.bankVerificationRequired && !this.props.autoPaymentsDecision) {
          logAmplitudeEvent("Bank verification success", this.logProps);
          this.props.history.push("/autopay_confirm");
          return;
        }
      }
      if (this.props.moreInfoRequired) {
        this.props.history.push("/moreinfo");
        return;
      }
      else {
        this.props.history.push("/review_plan");
        return;
      }
    }
    if (
      this.props.statusAt !== prevProps.statusAt &&
      this.props.statusId === this.statusId
    ) {
      if (this.props.selectedLoanOfferStatus === "SELECTED" && this.openLink) {
        this.openLink();
      }
    }
  }

  getOAuthData() {
    if (this.state.linkToken) {
      return
    }
    if (!this.props.loanAppId) {
      this.setState({errorMessage: 'Loan application ID not found.'})
      return;
    }
    const linkToken = localStorage.getItem(`link_token:${this.props.loanAppId}`)
    if (!linkToken) {
      this.setState({errorMessage: 'OAuth data not found.'})
      return;
    }
    this.setState({linkToken})
  }

  afterSkip(){
    if(this.props.status === "CONDITIONAL_APPROVAL"
        || this.props.status === "CONDITIONAL_APPROVAL_ACCOUNT_LINKED"
        || this.props.status === "CONDITIONAL_APPROVAL_DEBIT_ADDED") {
      if (this.props.moreInfoRequired) {
        return "/moreinfo";
      }
    } else {
      return "/review_plan";
    }
  }

  handleOnSkipButtonClick = () => {
     logAmplitudeEvent("Pressed Skip Button", this.logProps);
      const acceptPlaidTokenRequestId = uuid.v4();
      this.props.acceptPlaidToken(
          this.props.loanAppId,
          this.props.offerId,
          null,
          null,
          null,
          "DISABLED",
          acceptPlaidTokenRequestId
      );
    this.props.history.push(this.afterSkip());
  };

  handleOnExit = (err, metadata) => {
    window.removeEventListener("popstate", pushCurrentState);
    Log.info({err, metadata}, `Plaid Link Exit`);
    logAmplitudeEvent("Plaid Link Exit", {
      ...this.state.logProps,
      err,
      metadata
    })

    if (err){
      this.props.history.push("/plaid_error");
      return;
    }

    if (this.props.location.pathname === '/link_bank_oauth') {
      this.props.history.push("/link_bank");
    }
  };

  handleOnEvent = (eventName, metadata) => {
    Log.info({eventName, metadata}, `Plaid Link Event`);
    logAmplitudeEvent("Plaid Link Event", {
      ...this.state.logProps,
      eventName,
      metadata
    })
  };

  handleOnSuccess = async (token, metadata) => {
    window.removeEventListener("popstate", pushCurrentState);
    Log.info({token, metadata}, `Plaid Link Success`);
    logAmplitudeEvent("Plaid Link Success", {
      ...this.logProps,
      metadata
    });
    let accounts = [];
    let institution = {};
    if (metadata.accounts) {
      accounts = metadata.accounts.map(acc => {
          return { id: acc.id, mask: acc.mask }
      });
    }
    if (metadata.institution) {
      institution.id = metadata.institution.institution_id;
      institution.name = metadata.institution.name;
    }
    let autoPaymentsDecision;
    if (!this.props.bankVerificationRequired) {
      autoPaymentsDecision = "ENABLED";
    }
    const acceptPlaidTokenRequestId = uuid.v4();
    this.props.acceptPlaidToken(
      this.props.loanAppId,
      this.props.offerId,
      token,
      accounts,
      institution,
      autoPaymentsDecision,
      acceptPlaidTokenRequestId
    );
    // this.props.history.push('/purchase_complete');
  };

  handleOnClick = (event, openLink) => {
    // disable back history
    logAmplitudeEvent("Pressed Link Bank Account Button", this.logProps);
    pushCurrentState();
    window.addEventListener("popstate", pushCurrentState);
    // before linking to bank check loan status (should be 'truthInLendingAccepted')
    this.openLink = openLink;
    this.statusId = uuid.v4();
    this.props.getLoanStatus(
      this.props.token,
      this.props.loanAppId,
      this.statusId
    );
    // openLink();
  };

  render() {
    Log.info(this.props, `ConsumerLinkBankPage props`);
    const LoaderWithMessage = () => {
      if (this.props.isLoading || (this.props.location && this.props.location.pathname === '/link_bank_oauth')) {
        return (
            <div>
              <Loader />
              <div className={styles.message}>Wait a moment please...</div>
            </div>
        );
      }
      return null;
    };

    const HeaderContent = () => {
      const firstMonthPrepayment = this.props.firstMonthPrepayment;
      if (this.props.bankVerificationRequired) {
        return <PageHeader progress="57%">
          <div>Verify your info</div>
          <div>
            <div>
              To complete your application, please link an active bank account for verification&nbsp;purposes. 
            </div>
            <div style={{ marginTop: "20px" }}>
              Don't worry, we don't see or store your login info.
              This does not allow us to withdraw from&nbsp;your&nbsp;account.{" "}
              <span
                data-toggle="modal"
                data-target="#learnVerifyMoreModal"
                onClick={() => {
                  logAmplitudeEvent("Pressed Learn More Link", this.logProps);
                }}
              >
                Learn More
              </span>
            </div>
          </div>
        </PageHeader>
      }
      return <PageHeader progress="57%">
        <div>Set up Autopay</div>
        <div style={{ textAlign: "center" }}>
          {firstMonthPrepayment ?
              ["The debit card on file will only be used for your first payment. ",
                "Never miss future loan payments by also linking your bank account.", <br/>] :
              "Never miss a payment by linking your bank account. "
          }
          Don’t worry, we never see or store your&nbsp;login&nbsp;info.<br/>
              <span
                data-toggle="modal"
                data-target="#learnMoreModal"
                onClick={() => {
                  logAmplitudeEvent("Pressed Learn More Link", this.logProps);
                }}
              >
                Learn More
              </span>
        </div>
      </PageHeader>
    }

    const SubHeaderContent = () => {
      if (this.props.bankVerificationRequired) {
        return null;
      }
      return <div className={styles.text}>
        {this.isPayoutsListFound() ? "Autopay will apply to each loan for this service. " : ""}
        By enabling bank payments you agree to the{" "}
      <span
        data-toggle="modal"
        data-target="#paymentAuth"
        onClick={() => {
          logAmplitudeEvent("Pressed Payment Authorization Link", this.logProps);
        }}
      >
        payment authorization.
      </span>
      </div>
    }

    const skipButton = () => {
      if (this.props.bankVerificationRequired) {
        return null;
      }
      return <Buttons.Secondary style={{marginTop:"0"}}
          data-test-id="skip"
          onClick={this.handleOnSkipButtonClick}
        >
          SKIP
        </Buttons.Secondary>
    }

    let buttonText;
    if (this.props.bankVerificationRequired) {
      buttonText = 'VERIFY VIA BANK'
    } else {
      buttonText = 'LINK BANK ACCOUNT'
    }

    const linkButton = () => {
      let linkToken = null;
      let oauthHref = null;
      if (this.props.loanAppId && this.props.linkTokens[this.props.loanAppId]) {
        linkToken = this.props.linkTokens[this.props.loanAppId].token;
      }
      if (this.state.linkToken) {
        linkToken = this.state.linkToken;
      }
      if (!linkToken) {
        return null;
      }
      if (this.props.location.pathname === '/link_bank_oauth') {
        oauthHref = localStorage.getItem('oauth_href');
        if (!oauthHref) {
          return null;
        }
        localStorage.removeItem('oauth_href');
      }
      return (
          <PlaidLink
              onClick={this.handleOnClick}
              disabled={!this.props.loanAppId}
              clientName="Wisetack"
              token={linkToken}
              receivedRedirectUri={oauthHref}
              env={window._wtenv_?.REACT_APP_PLAID_ENV}
              onExit={this.handleOnExit}
              onEvent={this.handleOnEvent}
              onSuccess={this.handleOnSuccess}
          >
                  <span
                      className="material-icons"
                      style={{
                        fontSize: "16px",
                        marginRight: "5px",
                        paddingBottom: "3px"
                      }}
                  >
                    lock_outline
                  </span>
            {buttonText}
          </PlaidLink>
      )
    }

    return (
      <Container>
        <HeaderContent />
        <LoaderWithMessage />
        {!this.props.isLoading ? (
          <>
            <img className={styles.image} src={image} alt="bank-logo-link" />
            {!!this.props.errorMessage && (
              <div className="row">
                <div className="col">
                  <div className={styles.couldNotLinkBankWarnSquare}>
                    <div className={styles.couldNotLinkBankWarnIcon}><img src={warnImage} alt="info-img"/></div>
                    <div className={styles.couldNotLinkBankWarnText}>
                      {this.props.errorMessage}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <SubHeaderContent />
            <div className="row">
              <div className="col">
                { linkButton() }
              </div>
            </div>
            { skipButton() }
          </>
        ) : (
            <div style={{ paddingBottom: "15px" }} />
          )}
        <ConsumerLinkBankReadMoreModal />
        <ConsumerVerifyBankReadMoreModal />
        <PaymentAuthModal />
      </Container>
    );
  }
}

ConsumerLinkBankPage.propTypes = {
  loanAppId: PropTypes.string.isRequired,
  acceptPlaidToken: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  errorMessage: PropTypes.string,
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  initExpired: state.consumer.initExpired,
  token: state.consumer.token,
  offerId: state.consumer.acceptedOfferId || state.consumer.offerId,
  errorMessage:
    state.consumer.errorMessage ||
    (state.consumer.loanAppId
      ? ""
      : "We’re having trouble linking your bank account. Please try again."),
  isLoading: state.consumer.isLoading,
  status: state.consumer.status,
  selectedLoanOfferId: state.consumer.selectedLoanOfferId,
  selectedLoanOfferStatus: state.consumer.selectedLoanOfferStatus,
  statusId: state.consumer.statusId,
  statusAt: state.consumer.statusAt,
  bankVerificationRequired: state.consumer.bankVerificationRequired,
  moreInfoRequired: state.consumer.moreInfoRequired,
  acceptPlaidTokenRequestId: state.consumer.acceptPlaidTokenRequestId,
  linkTokens: state.consumer.linkTokens,
  autoPaymentsDecision: state.consumer.autoPaymentsDecision,
  firstMonthPrepayment: state.consumer.firstMonthPrepayment,
  payouts: state.consumer.payouts
});

export default connect(
  mapStateToProps,
  { getLoanStatus, acceptPlaidToken, createLinkToken}
)(ConsumerLinkBankPage);
