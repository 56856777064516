import React from "react";

import styles from "./AutoPay.module.scss";
import PlaidLink from "@wisetack/shared-ui/components/PlaidLink";
import PaymentAuthModal from "@wisetack/shared-ui/components/PaymentAuthModal";
import {acceptPlaidToken, createLinkToken, enableAutoPayments} from "../store/actions/consumerActions";
import uuid from "uuid";
import {connect} from "react-redux";
import error from "@wisetack/assets/img/error_red.svg";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";

class AutoPay extends React.Component {

    state = {
        errorMessage: null,
        linkToken: null,
        oauthHref: null,
        autoPayments: null
    }

    componentDidMount() {
        logAmplitudeEvent("AutoPay", this.logProps);
        if (this.props.loanAppId && !this.props.linkTokens[this.props.loanAppId]) {
            this.props.createLinkToken(this.props.loanAppId, uuid.v4());
        } else {
            this.setState({
                linkToken: this.props.linkTokens[this.props.loanAppId].token
            })
        }
    }

    handleOnExit = (err, metadata) => {
        if (err){
            this.setState({
                errorMessage: err.error_message
            })
        }
        logAmplitudeEvent("Plaid Link Exit", {
            ...this.state.logProps,
            err,
            metadata
        })
    }

    handleOnEvent = (eventName, metadata) => {
        logAmplitudeEvent("Plaid Link Event", {
            ...this.state.logProps,
            eventName,
            metadata
        })
    }

    handleOnSuccess = async (token, metadata) => {
        logAmplitudeEvent("Plaid Link Success", {
            ...this.logProps,
            metadata
        });
        let accounts = [];
        let institution = {};
        if (metadata.accounts) {
            accounts = metadata.accounts.map(acc => {
                return {id: acc.id, mask: acc.mask}
            });
        }
        if (metadata.institution) {
            institution.id = metadata.institution.institution_id;
            institution.name = metadata.institution.name;
        }
        const autoPaymentsDecision = "ENABLED";
        const acceptPlaidTokenRequestId = uuid.v4();
        this.props.acceptPlaidToken(
            this.props.loanAppId,
            this.props.offerId,
            token,
            accounts,
            institution,
            autoPaymentsDecision,
            acceptPlaidTokenRequestId
        );
        this.props.enableAutoPayments(autoPaymentsDecision);
    }

    handleOnClick = (event, openLink) => {
        logAmplitudeEvent("Pressed Link Bank Account Button", this.logProps);
        openLink();
    };

    isPlaidButtonDisabled = () => {
        return this.props.autoPayments === "ENABLED" || !this.props.loanAppId;
    }

    plaidButtonContent = () => {
        if (this.isPlaidButtonDisabled()) {
            return (
                <>
                    {"ACCOUNT LINKED - " + this.props.accountMask}
                </>
            )
        }
        return (
            <>
            <span className="material-icons"
              style={{
                  fontSize: "16px", marginRight: "5px", paddingBottom: "3px"
              }}
            >
                lock_outline
            </span>
                LINK BANK ACCOUNT
            </>
        )
    }

    render() {
        const ErrorBox = () => {
            return (
                <>
                    {
                        this.state.errorMessage &&
                        <div className={styles.errorBox}>
                            <img src={error} alt="error"/>
                            <div>
                                We’re having trouble linking your bank account. Please try again.
                            </div>
                        </div>
                    }
                </>
            )
        }

        return (
            <>
                <ErrorBox/>
                <div className={styles.background}>
                    <div className={styles.header}>
                        Set up Autopay
                    </div>
                    <div className={styles.text}>
                        Never miss a loan payment with Autopay and turn off at anytime.
                    </div>
                    <div className={styles.linkBankButton}>
                        <PlaidLink
                            disabled={this.isPlaidButtonDisabled()}
                            clientName="Wisetack"
                            token={this.state.linkToken}
                            receivedRedirectUri={this.state.oauthHref}
                            env={window._wtenv_?.REACT_APP_PLAID_ENV}
                            onExit={this.handleOnExit}
                            onEvent={this.handleOnEvent}
                            onSuccess={this.handleOnSuccess}
                            onClick={this.handleOnClick}
                        >
                            {this.plaidButtonContent()}
                        </PlaidLink>
                    </div>
                    <div className={styles.text}>
                        By linking your bank, you agree to the
                        <span
                            data-toggle="modal"
                            data-target="#paymentAuth"
                            onClick={() => {
                                logAmplitudeEvent("Pressed Payment Authorization Link", this.logProps);
                            }}
                        >
                            Autopay Authorization
                        </span>
                        .
                    </div>
                    <PaymentAuthModal/>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    loanAppId: state.consumer.loanAppId,
    linkTokens: state.consumer.linkTokens,
    autoPayments: state.consumer.autoPaymentsDecision,
    accountMask: state.consumer.accountMask,
    token: state.consumer.token,
});

export default connect(
    mapStateToProps,
    {acceptPlaidToken, createLinkToken, enableAutoPayments}
)(AutoPay);