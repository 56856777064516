// PLAID
export const PLAID_REQUEST_STARTED = "PLAID_REQUEST_STARTED";
export const PLAID_REQUEST_ENDED = "PLAID_REQUEST_ENDED";
export const PLAID_GET_ITEMS = "PLAID_GET_ITEMS";
export const PLAID_ERROR = "PLAID_ERROR";
export const PLAID_REGISTRATION = "PLAID_REGISTRATION";
export const PLAID_CLEAR_DATA = "PLAID_CLEAR_DATA";
export const PLAID_GET_PI = "PLAID_GET_PI";
export const PLAID_GET_INCOME = "PLAID_GET_INCOME";
export const PLAID_GET_ACCOUNT = "PLAID_GET_ACCOUNT";
export const PLAID_GET_BALANCE = "PLAID_GET_BALANCE";
export const PLAID_DELETE_ITEM = "PLAID_DELETE_ITEM";
// CONSUMER
export const CONSUMER_ERROR = "CONSUMER_ERROR";
export const FIELD_ERROR = "FIELD_ERROR";
export const SSN4_ERROR = "SSN4_ERROR";
export const APPLICATION_EXPIRED = "APPLICATION_EXPIRED";
export const CONSUMER_GET_LOAN_STATUS = "CONSUMER_GET_LOAN_STATUS";
export const CONSUMER_SUBMIT_DATA = "CONSUMER_SUBMIT_DATA";
export const CONSUMER_UPDATE_LOAN = "CONSUMER_UPDATE_LOAN";
export const CONSUMER_GET_OFFERS = "CONSUMER_GET_OFFERS";
export const CONSUMER_ACCEPT_OFFER = "CONSUMER_ACCEPT_OFFER";
export const CONSUMER_GET_TRUTH_IN_LENDING = "CONSUMER_GET_TRUTH_IN_LENDING";
export const CONSUMER_ACCEPT_TRUTH_IN_LENDING = "CONSUMER_ACCEPT_TRUTH_IN_LENDING";
export const CONSUMER_ACCEPT_PLAID_TOKEN = "CONSUMER_ACCEPT_PLAID_TOKEN";
export const CONSUMER_SUBMIT_PIN = "CONSUMER_SUBMIT_PIN";
export const CONSUMER_GET_PERSONA_INQUIRIES = "CONSUMER_GET_PERSONA_INQUIRIES";
export const CONSUMER_SUBMIT_PHONE = "CONSUMER_SUBMIT_PHONE";
export const CONSUMER_CLEAR_STATE = "CONSUMER_CLEAR_STATE";
export const CONSUMER_OFFER_SELECTED = "CONSUMER_OFFER_SELECTED";
export const CONSUMER_LOCK_OFFER = "CONSUMER_LOCK_OFFER";
export const CONSUMER_PURCHASE_RECEIVED = "CONSUMER_PURCHASE_RECEIVED";
export const CONSUMER_SESSION_EXPIRED = "CONSUMER_SESSION_EXPIRED";
export const CONSUMER_ESCALATE_AUTH = "CONSUMER_ESCALATE_AUTH";
export const CONSUMER_CREATE_LINK_TOKEN = "CONSUMER_CREATE_LINK_TOKEN";
export const CONSUMER_CHECKBOX_SELECTION = "CONSUMER_CHECKBOX_SELECTION";
export const CONSUMER_API_ERROR = "CONSUMER_API_ERROR";
export const CONSUMER_SELECT_PAYOUT = "CONSUMER_SELECT_PAYOUT";
export const CONSUMER_GET_DOCUMENTS_CONTENT = "CONSUMER_GET_DOCUMENTS_CONTENT";
export const CONSUMER_GET_CAPTURE_CONTEXT = "CONSUMER_GET_CAPTURE_CONTEXT";
export const CONSUMER_SUBMIT_CARD_DATA = "CONSUMER_SUBMIT_CARD_DATA";
export const INIT_CAPTURE_CONTEXT_SESSION = 'INIT_CAPTURE_CONTEXT_SESSION'
export const CONSUMER_NEURO_ID_SESSION_STARTED = "CONSUMER_NEURO_ID_SESSION_STARTED";
// prequal
export const CONSUMER_PREQUAL_SUBMIT = "CONSUMER_PREQUAL_SUBMIT";
export const CONSUMER_PREQUAL_GET = "CONSUMER_PREQUAL_GET";
export const CONSUMER_PREQUAL_PATCH = "CONSUMER_PREQUAL_PATCH";
export const CONSUMER_PREQUAL_PATCH_SELECT_OFFER = "CONSUMER_PREQUAL_PATCH_SELECT_OFFER";
export const CONSUMER_PREQUAL_PHONE = "CONSUMER_PREQUAL_PHONE";
export const CONSUMER_AUTOPREQUAL_INIT = "CONSUMER_AUTOPREQUAL_INIT";
export const CONSUMER_AUTOPAYMENTS_ENABLED = "CONSUMER_AUTOPAYMENTS_ENABLED";

// MERCHANT
export const MERCHANT_CREATE_LOAN = "MERCHANT_CREATE_LOAN";

//GENERIC LOADER
export const EXPECTED_RUN_LENGTH = "EXPECTED_RUN_LENGTH";