import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Log from "@wisetack/shared-ui/utils/Log";
import Error from "@wisetack/shared-ui/components/Error";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./ConsumerPurchaseCompletePage.module.scss";
import image1 from "@wisetack/assets/img/check-mark.svg";
import image2 from "@wisetack/assets/img/exclamation-mark.svg";
import moment from "moment";
import {getOffers} from "../store/actions/consumerActions";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import LearnMoreModal from "@wisetack/shared-ui/components/LearnMoreModal";
import {getVerticalFragment} from "../utils/verticalMessages";
import classNames from "classnames";
import {formatDate} from "../utils/format";
import AutoPay from "../components/AutoPay";

const pageName = "Purchase Complete Page"

class ConsumerPurchaseCompletePage extends React.Component {

  state = {}

  componentDidMount() {
    if (this.navigateToPayouts()) {
      return;
    }

    window.scrollTo(0, 0);

    logAmplitudeEvent(pageName, {
      loanId: this.props.loanAppId.substr(0, 8),
      merchantName: this.props.merchantName,
      page: pageName
    });

    if ((!this.props.selectedPlan || Object.keys(this.props.selectedPlan).length === 0) && this.props.loanAppId) {
      this.props.getOffers(this.props.loanAppId)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.initExpired) {
      Log.info(this.props.initExpired, `initExpired`);
      this.props.history.push("/expired");
    }
    if (this.props.errorMessage) {
      Log.info(this.props.errorMessage, `error`);
      this.props.history.push("/error");
    }
    this.navigateToPayouts();
  }

  isPayoutsListFound() {
    return this.props.payouts && this.props.payouts.length > 1
  }

  navigateToPayouts() {
    if (!this.isPayoutsListFound()) {
      return false;
    }
    for (const payout of this.props.payouts) {
      if (payout.status !== "OFFER_CONVERTED" && payout.status !== "SETTLED") {
        this.props.history.push("/loan_payouts");
        return true;
      }
    }
    return false;
  }

  handleOnBack = () => {
    this.props.history.push("/loan_payouts");
  }

  render() {
    const {
      selectedPlan,
      isLoading,
      errorMessage,
      merchantName,
      autoPayments,
      loanServicer,
      vertical,
      settlementDelay,
      settlementDate,
      firstMonthPrepayment,
      cardLastFourDigits
    } = this.props;
    Log.info(this.props, `ConsumerPurchaseCompletePage props`);
    let date = "";

    const plan = selectedPlan || {};
    if (plan.starting) {
      const starting = moment(plan.starting, "MM/DD/YYYY");
      if (starting.isValid()) {
        date = starting.format("LL");
      }
    }

    const LoaderWithMessage = () => {
      if (!isLoading) return null;
      return (
        <div>
          <Loader/>
          <div className={styles.message}>Wait a moment please...</div>
        </div>
      );
    };

    const bullet_point_1 = (merchantName, vertical, settlementDate, settlementDelay) => {

      if (vertical === 'LG' && settlementDelay) {
        return <li>
          <b>{merchantName}</b> will receive the funds on <span style={{whiteSpace: 'nowrap'}}><b>{formatDate(settlementDate, 'MM-DD-YYYY', 'MMM DD, YYYY')}</b></span>
        </li>
      }

      return <li>
        <b>{merchantName}</b> will receive the funds.<br/>
      </li>
    }

    const bullet_point_2 = (settlementDelay) => {
      if (settlementDelay) {
        return <li>
          Interest will not accrue until we send the funds
        </li>
      }
    }

    const bullet_point_3 = (loanServicer) => {
      return <li>
        {loanServicer === 'Peach' ?
            <>You'll get an email from us to manage your loan payments.</> :
            <>You'll receive an email from <b>{loanServicer}</b>, where you can manage your loan and make payments.</>
        }
        {
            this.isPayoutsListFound() &&
            <>
            <span className={styles.learnMore}
                  data-toggle="modal"
                  data-target="#learnMoreModal"
                  onClick={() => {
                    logAmplitudeEvent("Opened Learn more link", this.logProps);
                  }}
            >
              &nbsp;<b>Learn more.</b>
            </span>
              <br/>
            </>
        }
      </li>
    }

    const bullet_point_4 = (date, settlementDelay, firstMonthPrepayment, cardLastFourDigits) => {
      if (firstMonthPrepayment) {
        return <li>
          Your first installment is charged on <b>{date}</b>. We will use the card on file ending in *{cardLastFourDigits}.
        </li>
      }
      if (settlementDelay) {
        return <li>
          Your first payment will be due on <b>{date}</b>.
        </li>
      }
      return <li>
        Your first payment is due on <b>{date}</b>.
      </li>
    }

    const showContent = !isLoading && !errorMessage;

    const noContent = () => {
      return (
          <Container>
            <PageHeader progress="100%" displayChildren={showContent}/>
            <LoaderWithMessage/>
            <Error pageName={pageName}>{errorMessage}</Error>
            <div style={{paddingBottom: "15px"}}/>
          </Container>
      )
    }

    const payoutsContent = () => {
      return (
          <Container>
            <PageHeader progress="100%" displayChildren={showContent}>
              <Fragment>Hooray! Loan Confirmed!</Fragment>
              <Fragment/>
            </PageHeader>
            <img className={styles.image} src={image1} alt="check-mark"/>
            <LearnMoreModal loanServicer={loanServicer}/>
            <div className="row" style={{marginBottom: "5px"}}>
              <div className="col">
                <div className={styles.next}>
                  {autoPayments !== 'ENABLED' &&
                      <h5>What happens next?</h5>
                  }
                  <div className={styles.innerText}>
                    {bullet_point_1(merchantName, vertical, settlementDate, settlementDelay)}
                    {bullet_point_2(settlementDelay)}
                    {bullet_point_3(loanServicer)}
                    {bullet_point_4(date, settlementDelay, firstMonthPrepayment, cardLastFourDigits)}
                  </div>
                  {
                      autoPayments === 'ENABLED' &&
                      <div className={styles.autoPaymentsMessage}>
                              <span className="material-icons"
                                    style={{fontSize: "22px", verticalAlign: "bottom"}}>check_circle_outline</span>
                        <b> You've enabled automatic payments</b>
                      </div>
                  }
                  <button
                      data-test-id="purchase-complete-back"
                      className={classNames("btn", styles.buttonEnabled)}
                      onClick={this.handleOnBack}
                  >
                    BACK TO LOAN PAYOUTS
                  </button>
                  <div className={styles.support}>
                    <img className={styles.image} src={image2} alt="exclamation-mark"/>
                    <h5>{getVerticalFragment('purchase_complete_header', this.props.vertical)}</h5>
                    <div>
                      {getVerticalFragment('purchase_complete_message', this.props.vertical, {merchantName})}
                      <a href="mailto:support@wisetack.com">support@wisetack.com</a>.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
      )
    }

    const previousLoanAppContent = () => {
      return(
          <Container>
            <LearnMoreModal loanServicer={loanServicer}/>
            <PageHeader progress="100%">
              <Fragment>Hooray! Loan Confirmed!</Fragment>
              <Fragment/>
            </PageHeader>
            <img className={styles.image} src={image1} alt="check-mark"/>
            <div className="row" style={{marginBottom: "5px"}}>
              <div className="col">
                <div className={styles.next}>
                  <h5>What happens next?</h5>
                  <div className={styles.innerText}>
                    {bullet_point_1(merchantName, vertical, settlementDate, settlementDelay)}
                    {bullet_point_2(settlementDelay)}
                    {bullet_point_3(loanServicer)}
                    {bullet_point_4(date, settlementDelay, firstMonthPrepayment, cardLastFourDigits)}
                  </div>
                  {autoPayments === 'ENABLED' &&
                      <div className={styles.autoPaymentsMessage}>
                        <span className="material-icons" style={{ fontSize: "22px", verticalAlign: "bottom"}}>check_circle_outline</span>
                        <b> You've enabled automatic payments</b>
                      </div>
                  }
                  <div className={styles.support}>
                    <img className={styles.image} src={image2} alt="exclamation-mark"/>
                    <h5>{getVerticalFragment('purchase_complete_header', this.props.vertical)}</h5>
                    <div>
                      {getVerticalFragment('purchase_complete_message', this.props.vertical, {merchantName})}
                      <a href="mailto:support@wisetack.com">support@wisetack.com</a>.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
      )
    }
    const loanAppContent = () => {
      if (true) {
        //This is done intentionally. This view should be disabled right now
        return previousLoanAppContent();
      }
      return (
          <Container>
            <PageHeader progress="100%" displayChildren={showContent}>
              <Fragment>Hooray! Loan confirmed!<br/>See what’s next.</Fragment>
              <Fragment/>
            </PageHeader>
            <div className="row">
              <div className="col">
                <div className={styles.next}>
                  <ul className={styles.innerText}>
                    {bullet_point_1(merchantName, vertical, settlementDate, settlementDelay)}
                    {bullet_point_2(settlementDelay)}
                    {bullet_point_3(loanServicer)}
                    {bullet_point_4(date, settlementDelay, firstMonthPrepayment, cardLastFourDigits)}
                  </ul>
                  <AutoPay
                      loanAppId = {this.props.loanAppId}
                      offerId = {this.props.offerId}
                  />
                </div>
              </div>
            </div>
          </Container>
      )
    }

    return (
        <>
          {
            showContent && this.isPayoutsListFound() && payoutsContent()
          }
          {
            showContent && !this.isPayoutsListFound() && loanAppContent()
          }
          {
            !showContent && noContent()
          }
        </>
    );
  }
}

ConsumerPurchaseCompletePage.propTypes = {
  selectedPlan: PropTypes.object.isRequired,
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  selectedPlan: state.consumer.selectedPlan,
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  offerId: state.consumer.selectedLoanOfferId || state.consumer.offerId,
  isLoading: state.consumer.isLoading,
  errorMessage: state.consumer.errorMessage,
  initExpired: state.consumer.initExpired,
  autoPayments: state.consumer.autoPaymentsDecision,
  payouts: state.consumer.payouts,
  loanServicer: state.consumer.loanServicer,
  vertical: state.consumer.vertical,
  settlementDelay: state.consumer.settlementDelay,
  settlementDate: state.consumer.settlementDate,
  firstMonthPrepayment: state.consumer.firstMonthPrepayment,
  cardLastFourDigits: state.consumer.cardLastFourDigits
});

export default connect(
  mapStateToProps,
  {getOffers}
)(ConsumerPurchaseCompletePage);
