import React from "react";
import {useHistory} from "react-router";
import {connect, useSelector} from "react-redux";
import classNames from "classnames";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import image1 from "@wisetack/assets/img/check-mark.svg";
import LearnMoreModal from "@wisetack/shared-ui/components/LearnMoreModal";
import PurchaseConfirmationLoanOffer from "@wisetack/shared-ui/components/PurchaseConfirmationLoanOffer";

import usePayoutLog from "../hooks/usePayoutLog";
import usePayoutFlow from "../hooks/usePayoutFlow";
import styles from "./LoanPayoutCompletePage.module.scss";
import moment from "moment";

function LoanPayoutCompletePage() {
    const history = useHistory();

    const selectedPlan = useSelector(state => state.consumer.selectedPlan);
    const selectedPayout = useSelector(state => state.consumer.selectedPayout);
    const merchantName = useSelector(state => state.consumer.doingBusinessAs || state.consumer.merchantName);
    const autoPayments = useSelector(state => state.consumer.autoPaymentsDecision);
    const loanServicer = useSelector(state => state.consumer.loanServicer);

    const payoutData = usePayoutFlow(selectedPlan, selectedPayout);

    const logAmplitude = usePayoutLog("Loan Payout Complete Page")

    const handleOnBack = () => {
        logAmplitude("Back Button clicked")
        history.push("/loan_payouts")
    }

    return <Container>
        <PageHeader progress="100%">
            <div>Hooray! {payoutData.approvedAmount} payout confirmed!</div>
            <div/>
        </PageHeader>
        <img className={styles.image} src={image1} alt="check-mark"/>
        <div className="row" style={{marginBottom: "5px"}}>
            <div className="col">
                <div className={styles.next}>
                    <h5>What happens next?</h5>
                    <div className={styles.innerText}>
                        • <b>{merchantName}</b> will receive the funds.<br/>
                        • You’ll get an email from us to manage your loan payments.
                        <span
                            data-toggle="modal"
                            data-target="#learnMoreModal"
                            onClick={() => {
                                logAmplitude("Clicked Learn more link")
                            }}
                        >
                    &nbsp;<b>Learn more.</b>
                    </span><br/>
                        • Your first payment is due on <b>{selectedPayout && selectedPayout.starting ? moment(selectedPayout.starting, "MM/DD/YYYY").format("LL") : ""}</b>.
                        <br/>
                        • View your <span
                        data-toggle="modal"
                        data-target="#purchaseConfirmationLoanOffer"
                        onClick={() => {
                            logAmplitude("Clicked Loan Offer link", {payoutData})
                        }}
                    ><b>loan offer</b></span>.
                    </div>
                    {autoPayments === 'ENABLED' &&
                        <div className={styles.autoPaymentsMessage}>
                            <span className="material-icons" style={{ fontSize: "22px", verticalAlign: "bottom"}}>check_circle_outline</span>
                            <b> You've enabled automatic payments</b>
                        </div>
                    }
                    <hr/>
                    <button
                        data-test-id="purchase-complete-back"
                        className={classNames("btn", styles.buttonEnabled)}
                        onClick={handleOnBack}
                    >
                        BACK TO PAYOUTS
                    </button>
                    <div className={styles.bottomMessage}>
                        If you wish to stop funds or have questions, please contact <a href="mailto:support@wisetack.com">support@wisetack.com</a>.
                    </div>
                </div>
            </div>
        </div>
        <LearnMoreModal loanServicer={loanServicer}/>
        <PurchaseConfirmationLoanOffer selectedPlan={payoutData} loanAppExpirationDate={payoutData.expDate}/>
    </Container>
}

export default connect(null, {})(
    LoanPayoutCompletePage
);
