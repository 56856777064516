"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NeuroID = void 0;

var _NeuroIDClient = require("./NeuroIDClient");

var _NeuroIDClientDisabled = require("./NeuroIDClientDisabled");

var NeuroID = function () {
  var instance;
  var snippetUrl;
  var initiated = false;
  return {
    init: function init(url) {
      if (initiated) return;

      if (!url) {
        initiated = true;
        return;
      }

      (function (n, e, u, r, o, i, d) {
        n.nid = n.nid || function () {
          (n.nid.q = n.nid.q || []).push(arguments);
        };

        o = e.createElement(u);
        i = e.getElementsByTagName(u)[0];
        o.async = 1;
        o.src = r;
        i.parentNode.insertBefore(o, i);
      })(window, document, 'script', url);

      snippetUrl = url;
      initiated = true;
    },
    getInstance: function getInstance() {
      if (instance != null) {
        return instance;
      }

      if (snippetUrl) {
        instance = new _NeuroIDClient.NeuroIDClient();
      } else {
        instance = new _NeuroIDClientDisabled.NeuroIDClientDisabled();
      }

      instance.constructor = null;
      return instance;
    }
  };
}();

exports.NeuroID = NeuroID;