import React, {Fragment} from "react";
import {parse} from 'query-string';
import PropTypes from "prop-types";
import faker from "faker/locale/en_US";
import Log from "@wisetack/shared-ui/utils/Log";
import {LoaderWithMessage} from "../components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import beep from "@wisetack/shared-ui/utils/Beep";
import {connect} from "react-redux";
import styles from "./ConsumerEntryPage.module.scss";
import classNames from "classnames";
import Container from "@wisetack/shared-ui/components/Container";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import TermsOfService from "@wisetack/shared-ui/components/TermsOfService";
import PrivacyPolicyModal from "@wisetack/shared-ui/components/PrivacyPolicyModal";
import ElectronicDisclousersModal from "@wisetack/shared-ui/components/ElectronicDisclosuresModal";
import FormDateInput from "@wisetack/shared-ui/components/FormDateInput";
import {formatCurrency, formatSSN, getFormattedAmount} from "@wisetack/shared-ui/utils/format";
import {
    getLoanStatus,
    getZip,
    neuroIdSetSessionStarted,
    setError,
    submitCheckboxSelection,
    submitData
} from "../store/actions/consumerActions";
import {loadToken} from "@wisetack/shared-ui/utils/localStorage";
import {logAmplitudeEvent, setAmplitudeUserProperty} from "@wisetack/shared-ui/components/Amplitude";
import {BorrowerFieldValidator} from "@wisetack/shared-ui/utils/BorrowerFieldValidator"
import simulateMouseClick from "@wisetack/shared-ui/src/utils/click";
import {getAddressSuggestions} from "@wisetack/shared-ui/utils/SmartyStreetsApi";
import SmartyStreetsAutocomplete from "@wisetack/shared-ui/components/SmartyStreetsAutocomplete";
import {getPageActivity, trackAddressPageSubmission, trackPageActivity} from "../utils/addressPageActivity";
import {isActiveLoansFound} from "../hooks/useDashboardFlow";
import {postIframeEvent} from "@wisetack/shared-ui/src/utils/iframeEventProducer";
import ConsumerEntryPageMoreInfoModal from "@wisetack/shared-ui/components/ConsumerEntryPageMoreInfoModal";
import {NeuroID} from "@wisetack/shared-ui/utils/neuroid/NeuroID"

faker.locale = "en_US";

const formFields = [
    "firstName",
    "lastName",
    "dob",
    "email",
    "annualIncomeBeforeTaxes",
    "zip",
    "ssn4",
    "ssn",
    "streetAddress1",
    "addressSecondaryNumber",
    "city",
    "stateCode",
    "employer",
    "reviewed"
];

const pageName = "Consumer Entry Page"
const page2Name = "Verify Home Address Page"

const pathCondition = (path, props) => {
    if (path === '/dashboard') {
        // According to the requirements (clarified with Kevin) display dashboard
        // only for new applications with status 'PENDING'
        return isActiveLoansFound(props.activeLoans) &&
            !props.location.search.includes('direct') &&
            props.status === 'PENDING' &&
            !pathCondition('/phone', props) &&
            !pathCondition('/phone_return', props)
    }

    // BORROWER_PROVIDED_BORROWER_PHONE
    if (path === '/phone') {
        return !props.pinVerified && ((!!props.fieldsRequired && props.fieldsRequired.phone) || props.profileFound)
    }

    // BORROWER_PROVIDED_AUTH_PIN
    if (path === '/pin') {
        return !!props.fieldsRequired && props.fieldsRequired.pin
    }

    // LINKED_BORROWER_PHONE_VERIFICATION
    if (path === '/phone_return') {
        return !!props.fieldsRequired && props.fieldsRequired.linked_phone
    }

    if (path === '/frozen') {
        return !!props.fieldsRequired && props.fieldsRequired.unfreeze
    }

    return false
}

//return [nextPage, stopProcessing]
//the stopProcessing only makes sense for ConsumerEntryPage
// separated to be reused

let nextPageForStatus = (props) => {
    let page;
    if (pathCondition('/dashboard', props)) {
        return ['/dashboard', true];
    }
    if (props.status === 'CANCELED') {
        return ['/purchase_canceled', true];
    } else if (props.refunded === true) {
        return ['/purchase_refunded', true];
    } else if (props.offerLock && props.offerLock.declined) {
        return ['/merchant_declined', true];
    } else if (props.offerLock && props.offerLock.expired) {
        return ['/merchant_expired', true];
    } else if (props.status === "OFFER_AVAILABLE" || props.status === "CONDITIONAL_APPROVAL") {
        const params = parse(window.location.search)
        if (params.oauth_state_id) {
            // redirect to Plaid link on OAuth
            return ["/link_bank_oauth", true];
        }
        switch (props.selectedLoanOfferStatus) {
            case "SELECTED":
                page = "/choose_plan";
                break;
            case "TILA_ACCEPTED":
            case "CONVERTED":
                if (props.emailVerificationRequired === true) {
                    page = "/email_confirm";
                } else if (props.payoutsList && props.payoutsList.length > 1) {
                    page = "/loan_payouts_return";
                } else {
                    page = "/purchase_confirm";
                }
                break;
            default:
                if (props.prequalStatus === 'PREQUALIFIED') {
                    page = "/prequal_back";
                } else {
                    page = "/choose_plan";
                }
                break;
        }
    } else if (props.status === "REJECTED") {
        if (
            props.rejectReasonsList &&
            (props.rejectReasonsList.includes("STALE_LOAN_APPLICATION") ||
                props.rejectReasonsList.includes(
                    "STALE_PENDING_LOAN_APPLICATION"
                ))
        ) {
            page = "/expired";
        } else {
            page = "/rejected";
        }
    } else if (props.status === "EXPIRED") {
        page = "/expired";
    } else if (props.status === "DECLINED") {
        if (props.declineReasonsList && props.declineReasonsList.length === 1 && props.declineReasonsList[0] === 'INELIGIBLE_FOR_ADDITIONAL_LOAN') {
            page = "/declined_max";
        } else {
            page = "/declined";
        }
    } else if (props.status === "ACCOUNT_LINKED") {
        page = "/review_plan";
    } else if (props.status === "CONDITIONAL_APPROVAL_ACCOUNT_LINKED") {
        if (props.bankVerificationRequired && props.balanceDecision === "INELIGIBLE") {
            page = "/link_bank";
        } else if (props.moreInfoRequired) {
            page = "/moreinfo";
        } else {
            page = "/review_plan";
        }
    } else if (props.status === "DOCUMENTS_SIGNED") {
        if (props.emailVerificationRequired === true) {
            page = "/email_confirm";
        } else if (props.payoutsList && props.payoutsList.length > 1) {
            page = "/loan_payouts_return";
        } else {
            page = "/purchase_confirm";
        }
    } else if (props.status === "OFFER_CONVERTED" || props.status === "PAYOUTS_CONVERTED" ||
        props.status === "SETTLED" || props.status === "PAYOUTS_SETTLED") {
        if (props.lockRequired) {
            page = "/purchase_complete_lock";
        } else {
            page = "/purchase_complete";
        }
    } else if (props.status === "CONDITIONAL_APPROVAL_DEBIT_ADDED") {
        if (props.moreInfoRequired) {
            page = "/moreinfo";
        } else {
            page = "/choose_plan";
        }
    }
    return [page, false];
}


class ConsumerEntryPage extends React.Component {
    state = {
        firstName: "",
        lastName: "",
        email: "",
        annualIncomeBeforeTaxes: "",
        zip: "",
        ssn4: "",
        ssn: "",
        streetAddress1: "",
        addressSecondaryNumber: "",
        city: "",
        stateCode: "",
        employer: "",
        day: "",
        month: "",
        year: "",
        errors: {},
        reviewed: this.props.confirmed,
        addressPage: false,
        fieldsChanged: {},
        amplitude: false,
        validProps: false,
        wasCleared: false,
        checkedZip: true,
        checkingZipCode: false,
        suggestions: [],
        autoCompleteLoading: false,
        autocompleteAvailable: true
    }

    constructor(props) {
        super(props);
        this.incomeInput = React.createRef();
        this.zipInput = React.createRef();
        this.ssnInput = React.createRef();
        this.validator = new BorrowerFieldValidator(pageName);
        this.autoCompleteTimer = null
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let token = this.props.match.params.token;
        if (token) {
            const prevToken = loadToken();
            if (prevToken && prevToken !== token) {
                logAmplitudeEvent(pageName + " (Application switched on page mount)", {
                    prevToken: prevToken,
                    newToken: token
                });
                this.clearFields();
            }
            this.props.getLoanStatus(token, null, null, !this.props.pinVerified);
        }
    }

    componentDidUpdate(prevProps) {
        let token = this.props.match.params.token;
        if (token && prevProps.token && token !== prevProps.token && !this.props.isLoading) {
            logAmplitudeEvent(pageName + " (Application switched on page update)", {
                prevToken: prevProps.token,
                newToken: token
            });
            this.props.getLoanStatus(token, null, null, true);
            this.clearFields();
            return;
        }
        if (!this.state.wasCleared) {
            let toClear = {};
            for (const [key, value] of Object.entries(this.props.fieldsValue)) {
                if (value === "") {
                    toClear[key] = value;
                }
            }
            this.setState({wasCleared: true, ...toClear});
        }
        if (this.props.initExpired) {
            Log.info(this.props.initExpired, `initExpired`);
            this.props.history.push("/expired");
        }
        if (this.props.errorMessage) {
            Log.info(this.props.errorMessage, `error`);
            if (this.props.errorMessage.includes('lockout')) {
                this.props.setError(null);
                this.props.history.push("/account_locked");
                return;
            }
            this.props.history.push("/error");
        }
        if (this.props.loanAppId) {
            let loanId = this.props.loanAppId.substr(0, 8);
            this.logProps = {
                loanId: loanId,
                merchantName: this.props.merchantName,
                merchantId: this.props.merchantId,
                status: this.props.status,
                page: pageName
            }
            if (!this.state.validProps) {
                this.validator.props = this.logProps;
                this.setState({validProps: true});
            }
        } else {
            // no redirection if loanAppId unknown (to prevent looping)
            return;
        }

        this.neuroIdTracking()

        for (const path of ['/phone', '/pin', '/phone_return', '/frozen']) {
            if (pathCondition(path, this.props)) {
                this.props.history.push(path);
                return;
            }
        }

        let pageRes = nextPageForStatus(this.props);
        if (pageRes[0]) {
            this.props.history.push(pageRes[0]);
        }
        if (pageRes[1]) { //original code returned on some pages
            return;
        }

        if (!this.state.amplitude && !this.props.isLoading
            && this.logProps !== undefined && this.logProps.hasOwnProperty('loanId')
            && this.logProps.status === "PENDING") {
            setAmplitudeUserProperty("loanId", this.logProps.loanId);
            setAmplitudeUserProperty("merchantId", this.props.merchantId);
            logAmplitudeEvent(pageName, this.logProps);
            this.setState({amplitude: true});
        }
        if (
            (this.props.fieldsRequired.streetAddress1 ||
                this.props.fieldsRequired.city ||
                this.props.fieldsRequired.stateCode ||
                this.props.fieldsRequired.zip) &&
            !this.state.addressPage
        ) {
            this.setState({addressPage: true, checkedZip: false});
            const prefilledZip = this.props.fieldsValue['zip'];
            if (prefilledZip && prefilledZip.length === 5) {
                getZip(prefilledZip).then(data => {
                    if (data.state) {
                        this.setState({checkedZip: true});
                    } else {
                        this.setError('zip', 'Invalid zip code');
                        logAmplitudeEvent('Validation Error', {
                            application: "Borrower",
                            field: "Zip Code",
                            value: prefilledZip,
                            message: "Invalid zip code",
                            page: page2Name
                        });
                    }
                });
            }
            logAmplitudeEvent(page2Name, {
                ...this.logProps,
                page: page2Name
            });
            postIframeEvent({event: 'INITIATED'});

            if (this.props.neuroIdSessionStarted) {
                NeuroID.getInstance().stateChange(page2Name)
            }
        }

        // 'PENDING'
    }

    neuroIdTracking() {

        if (this.props.neuroIdSessionStarted) {
            return
        }

        const loanAppId = this.props.loanAppId
        if (!loanAppId) {
            return
        }

        const skipTrackingForDashboardView = isActiveLoansFound(this.props.activeLoans)
            && this.props.status === 'PENDING'

        if (skipTrackingForDashboardView) {
            return
        }

        const piiPage = this.props.neuroIdPiiPage

        if (piiPage) {
            this.startAndIdentifyNeuroId(pageName, this.props.loanAppId)
            this.props.neuroIdSetSessionStarted(true)
            return
        }

        const addressPage = this.props.neuroIdAddressPage

        if (addressPage) {
            this.startAndIdentifyNeuroId(page2Name, this.props.loanAppId)
            this.props.neuroIdSetSessionStarted(true)
        }
    }

    startAndIdentifyNeuroId(pageName, identifier) {
        const neuroID = NeuroID.getInstance()
        neuroID.start(pageName)
        neuroID.identify(identifier)
    }

    stopNeuroIdTracking(pageName) {
        const neuroID = NeuroID.getInstance()
        neuroID.applicationSubmitWithCallback(
            pageName, neuroID.closeSession, 5000
        )
    }

    clearFields = () => {
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            annualIncomeBeforeTaxes: "",
            zip: "",
            ssn4: "",
            ssn: "",
            streetAddress1: "",
            addressSecondaryNumber: "",
            city: "",
            stateCode: "",
            employer: "",
            day: "",
            month: "",
            year: ""
        });
    }

    validateField = (name, val) => {
        switch (name) {
            case "firstName":
                return this.validator.validateFirstName(val);
            case "lastName":
                return this.validator.validateLastName(val);
            case "email":
                return this.validator.validateEmail(val, suggestion => this.setState({suggestions: {email: suggestion}}));
            case "annualIncomeBeforeTaxes":
                return this.validator.validateIncome(val);
            case "zip":
                let validation = this.validator.validateZipCode(val);
                if (!validation && !this.state.checkedZip) {
                    this.setState({checkingZipCode: true});
                    getZip(val).then(data => {
                        this.setState({checkingZipCode: false});
                        if (data.state) {
                            this.setState({checkedZip: true});
                        } else {
                            this.setError('zip', 'Invalid zip code');
                            logAmplitudeEvent('Validation Error', {
                                application: "Borrower",
                                field: "Zip Code",
                                value: val,
                                message: "Invalid zip code",
                                page: page2Name
                            });
                        }
                    });
                }
                return validation;
            case "ssn4":
                return this.validator.validateSSN4(val);
            case "ssn":
                return this.validator.validateSSN(val);
            case "streetAddress1":
                return this.validator.validateAddress(val);
            case "city":
                return this.validator.validateCity(val);
            case "stateCode":
                return this.validator.validateState(val);
            case "employer":
                return this.validator.validateEmployer(val);
            default:
                return "";
        }
    };

    handleOnFocus = e => {
        let val = e.target.value;
        let name = e.target.name;
        if (name === "annualIncomeBeforeTaxes" && !val) {
            this.setState({annualIncomeBeforeTaxes: "$"});
        }
    };

    handleOnBlur = e => {
        let val = e.target.value;
        let name = e.target.name;
        if (
            name === "annualIncomeBeforeTaxes" &&
            (!val || val.length === 1 || val[1] === 0)
        ) {
            this.setState({annualIncomeBeforeTaxes: ""});
        } else if (name === "annualIncomeBeforeTaxes" && val) {
            val = formatCurrency(val, 0);
            if (val === ".00") val = "";
            this.setState({annualIncomeBeforeTaxes: val});
        }
        const error = this.validateField(name, val);
        this.setError(name, error);
    };

    setError(name, error) {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    autoFill = (name, val) => {
        if (val === "~") {
            switch (name) {
                case "firstName":
                    return faker.name.firstName();
                case "lastName":
                    return faker.name.lastName();
                case "email":
                    return "test+borrower@wisetack.com";
                case "zip":
                    return faker.address.zipCode();
                case "ssn4":
                    return "1234";
                case "ssn":
                    return "123121234";
                case "streetAddress1":
                    return faker.address.streetAddress();
                case "city":
                    return faker.address.city();
                case "stateCode":
                    return faker.address.stateAbbr();
                case "employer":
                    return faker.company.companyName();
                case "annualIncomeBeforeTaxes":
                    return faker.finance.amount();
                default:
                    return val;
            }
        }
        return val;
    };

    handleOnChange = e => {
        let val = e.target.value;
        let name = e.target.name;
        let newVal = "";

        val = this.autoFill(name, val);

        if (name === "firstName") {
            val = val.replace(/[^a-zA-Z.\-'\s]/g, "");
        }
        if (name === "lastName") {
            val = val.replace(/[^a-zA-Z.\-'\s]/g, "");
        }
        if (name === "zip") {
            val = val.replace(/\D/g, "");
            if (val.length > 5) {
                beep();
                return;
            }
            if (val.length === 5) {
                this.setState({checkedZip: false}, () => {
                    const error = this.validateField(name, val);
                    this.setError(name, error);
                    if (error) {
                        trackPageActivity("invalidZip");
                    }
                });
            }
        }
        if (name === "ssn4") {
            val = val.replace(/\D/g, "");
            if (val.length > 4) {
                beep();
                return;
            }
        }
        if (name === "ssn") {
            val = formatSSN(val);
        }
        if (name === "annualIncomeBeforeTaxes") {
            val = val.replace(/[^.,0-9]/g, "")
            if (val) {
                if (val === "~") {
                    newVal = formatCurrency(faker.finance.amount());
                } else {
                    newVal = getFormattedAmount(val, this.state.annualIncomeBeforeTaxes);
                }
            }
            this.setState({annualIncomeBeforeTaxes: newVal});
        }
        if (name === "stateCode") {
            val = val.replace(/\d/g, "").toUpperCase();
            if (val.length > 2) {
                beep();
                return;
            }
        }
        if (name === "city") {
            val = val.replace(/[0-9]/g, "");
        }
        if (name !== "annualIncomeBeforeTaxes") this.setState({[name]: val});
        if (name === "addressSecondaryNumber") {
            this.setState({[name]: val});
        }
        this.setState({fieldsChanged: {...this.state.fieldsChanged, [name]: true}});
        this.setError(name, "");
    };

    setPayload = (payload, name) => {
        if (this.props.fieldsRequired.streetAddress1
            && name === 'addressSecondaryNumber'
            && !!this.state[name]) {
            payload[name] = this.state[name];
        }
        if (!this.props.fieldsRequired[name] && name !== 'reviewed') {
            //Only add required fields to payload.
            return;
        }
        if (name === "dob") {
            if ((this.state.day || this.props.fieldsValue.day) && (this.state.month || this.props.fieldsValue.month) && (this.state.year || this.props.fieldsValue.year)) {
                payload[name] = `${this.state.year || this.props.fieldsValue.year}-${this.state.month || this.props.fieldsValue.month}-${this.state.day || this.props.fieldsValue.day}`;
            }
            return;
        }
        if (this.state[name]) {
            payload[name] = this.state[name];
        } else if (this.props.fieldsValue[name]) {
            payload[name] = this.props.fieldsValue[name];
        }
    };

    updateStateFromProps = (props, name) => {
        if (name === "dob") {
            return;
        }
        this.setState({[name]: props[name]});
    };

    handleOnButtonClick = () => {
        const payload = {};
        formFields.forEach(name => this.setPayload(payload, name));
        if (this.state.addressPage) {
            trackAddressPageSubmission(payload);
            logAmplitudeEvent("Pressed Continue Button", {
                ...this.logProps,
                page: page2Name,
                pageActivity: getPageActivity()
            });
            payload['applicationSubmitted'] = true;
            payload['async'] = true;

            this.stopNeuroIdTracking(page2Name)
        } else {
            logAmplitudeEvent("Pressed Continue Button", {
                ...this.logProps,
                income: payload["annualIncomeBeforeTaxes"]
            });
        }
        this.props.submitData(this.props.loanAppId, payload);
        this.setState({wasCleared: false});
    };

    handleOnEnterPress = event => {
        event.preventDefault();
        if (this.state.reviewed && event.key === 'Enter') {
            this.handleOnButtonClick();
        }
    }

    isFieldRequired = name => {
        if (!this.props.fieldsRequired[name]) {
            return false;
        }
        if (name === 'addressSecondaryNumber') {
            return false;
        }
        if (name === 'dob') {
            if ((this.state.day || this.props.fieldsValue.day) && (this.state.month || this.props.fieldsValue.month) && (this.state.year || this.props.fieldsValue.year)) {
                return false;
            }
        } else if (this.state[name]) {
            return false;
        }
        if (this.props.fieldsValue[name]) {
            Log.info(`Field "${name}" is prefilled with value "${this.props.fieldsValue[name]}"`, `isFieldRequired`);
            return false;
        }
        Log.info(`Field "${name}" is required and empty`, `isFieldRequired`);
        return true;
    }

    getEditValue = name => {
        if (this.state.fieldsChanged[name] || this.state[name]) {
            return this.state[name];
        }
        if (this.props.fieldsValue[name]) {
            return this.props.fieldsValue[name];
        }
        return "";
    };

    isButtonDisabled = () => {
        if (this.props.isLoading) return true;
        if (this.props.errorMessage) return true;
        if (!this.state.addressPage) {
            if (!this.state.reviewed) return true;
            if (!this.props.checkboxes['DATA_COLLECTION_PAGE']) {
                return true;
            }
        }
        for (const key in this.state.errors) {
            if (this.state.errors[key]) return true;
        }
        for (const fieldName of formFields) {
            if (this.isFieldRequired(fieldName)) {
                return true;
            }
        }
        return !this.state.checkedZip;
    };


    render() {
        Log.info(this.props, `ConsumerEntryPage props`);
        let btnDisabled = this.isButtonDisabled();

        const btnClasses = classNames({
            btn: true,
            "btn-block": true,
            "btn-disabled": btnDisabled,
            [styles.buttonDisabled]: btnDisabled,
            [styles.buttonEnabled]: !btnDisabled
        });

        const showContent = !this.props.isLoading && !this.props.errorMessage; // && this.props.merchantName;

        const Title = () => {
            if (!showContent) return <div/>;
            return <Fragment>We need a little more&nbsp;info</Fragment>;
        };

        const SubTitle = () => {
            if (!showContent) return <div/>;
            return (
                <div style={{textAlign: "center"}}>
                    Tell us a little bit more about yourself so we can show you
                    your&nbsp;options.
                    <span
                        data-toggle="modal"
                        data-target="#consumerEntryPageMoreInfo"
                        onClick={() => {
                            logAmplitudeEvent("Clicked More Info of Consumer Address Page Link", this.logProps);
                        }}
                        className={classNames("material-icons", this.state.addressPage ? styles.infoIcon : styles.hideInfoIcon)}>info_outline</span>
                </div>
            );
        };
        const progress = () => {
            if (this.props.fieldsRequired.firstName && this.props.fieldsRequired.lastName) {
                return "14%";
            } else if (this.state.addressPage) {
                return "28%";
            } else {
                return "0%";
            }
        }

        return (
            <Container>
                <PageHeader progress={progress()}>
                    <Title/>
                    <SubTitle/>
                </PageHeader>
                <LoaderWithMessage isLoading={this.props.isLoading}
                                   duration={this.state.addressPage ? this.props.expectedRunLength : ""}/>
                <Error pageName={this.state.addressPage ? page2Name : pageName}>{this.props.errorMessage}</Error>
                {showContent ? (
                    <Fragment>
                        <Form>
                            <FormRow>
                                <FormInput
                                    name="firstName"
                                    label="First name"
                                    value={this.getEditValue("firstName")}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.firstName}
                                    data-neuro-label="firstName"
                                />
                                <FormInput
                                    name="lastName"
                                    label="Last name"
                                    value={this.getEditValue("lastName")}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.lastName}
                                    data-neuro-label="lastName"
                                />
                            </FormRow>
                            <FormRow>
                                <FormInput
                                    type="email"
                                    name="email"
                                    label="Personal Email"
                                    value={this.getEditValue("email")}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.email}
                                    suggestions={this.state.suggestions}
                                    data-neuro-label="email"
                                />
                            </FormRow>
                            <FormRow>
                                <FormDateInput
                                    name="dob"
                                    label="Date of birth"
                                    month={this.getEditValue("month")}
                                    day={this.getEditValue("day")}
                                    year={this.getEditValue("year")}
                                    onChange={this.handleOnChange}
                                    onFocus={() => {
                                        this.setError("dob", "");
                                    }}
                                    onBlur={() => {
                                        const error = this.validator.validateDateOfBirth(this.getEditValue("month"), this.getEditValue("day"), this.getEditValue("year"));
                                        this.setError("dob", error);
                                    }}
                                    nextInput={this.incomeInput}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.dob}
                                    data-neuro-label="dob"
                                />
                            </FormRow>
                            <FormRow>
                                <FormInput
                                    type="text"
                                    min="0"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    name="annualIncomeBeforeTaxes"
                                    label="Annual Income"
                                    value={this.getEditValue("annualIncomeBeforeTaxes")}
                                    innerRef={this.incomeInput}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    onFocus={this.handleOnFocus}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.annualIncomeBeforeTaxes}
                                    data-neuro-label="income"
                                />
                            </FormRow>
                            <FormRow>
                                <FormInput
                                    type="text"
                                    min="0"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    name="ssn4"
                                    label="Last 4 SSN"
                                    value={this.getEditValue("ssn4")}
                                    innerRef={this.ssnInput}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.ssn4}
                                    data-neuro-label="ssnLast4"
                                />
                            </FormRow>
                            <FormRow>
                                <FormInput
                                    type="text"
                                    min="0"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    name="ssn"
                                    label="SSN"
                                    value={this.getEditValue("ssn")}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.ssn}
                                />
                            </FormRow>
                            <FormRow>
                                {this.props.fieldsRequired.streetAddress1 && (
                                    <SmartyStreetsAutocomplete
                                        label={"Home address"}
                                        loading={this.state.autoCompleteLoading}
                                        options={this.props.suggestions ? this.props.suggestions : []}
                                        onKeyUp={(e) => {
                                            trackPageActivity("addressOnKeyUp", e);
                                            clearTimeout(this.autoCompleteTimer);
                                            this.setError("streetAddress1", false);
                                            this.setState({
                                                streetAddress1: e.target.value,
                                                autoCompleteLoading: this.state.autocompleteAvailable
                                            });
                                            if (!this.state.autocompleteAvailable) {
                                                return;
                                            }
                                            this.autoCompleteTimer = setTimeout(() => {
                                                    this.props.getAddressSuggestions(e.target.value, 7, (autocompleteAvailable) => {
                                                        this.setState({
                                                            autoCompleteLoading: false,
                                                            autocompleteAvailable: autocompleteAvailable
                                                        })
                                                    })
                                                }, 400
                                            );
                                        }}
                                        onChange={(e, v) => {
                                            if (!v) {
                                                return;
                                            }
                                            trackPageActivity("autocompleteAddressSelected", e);
                                            this.setState({
                                                streetAddress1: (v.suggestion.street_line + " " + v.suggestion.secondary).trim(),
                                                city: v.suggestion.city,
                                                zip: v.suggestion.zipcode,
                                            }, () => {
                                                simulateMouseClick(document.getElementById("zipLabel"));
                                                simulateMouseClick(document.getElementById("cityLabel"));
                                                simulateMouseClick(document.getElementById("addressSecondaryNumberLabel"));
                                                logAmplitudeEvent("SmartyStreets autocomplete option selected!", {
                                                    addressLength: this.state.streetAddress1.length
                                                });
                                            });
                                        }}
                                        onBlur={() => {
                                            let value = this.getEditValue("streetAddress1");
                                            let error = !value || (/^[\d\W]+$/).test(value);
                                            this.setError("streetAddress1", error);
                                            if (error) {
                                                trackPageActivity("invalidAddress");
                                            }
                                        }}
                                        error={this.state.errors['streetAddress1']}
                                        stopRequest={() => clearTimeout(this.autoCompleteTimer)}
                                        data-neuro-label="address"
                                    />
                                )}
                            </FormRow>
                            <FormRow>
                                <FormInput
                                    type="text"
                                    name="addressSecondaryNumber"
                                    label={"Suite, Apt, Unit (optional)"}
                                    value={this.getEditValue("addressSecondaryNumber")}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.streetAddress1}
                                    turnOffAutoFill={true}
                                    onKeyUp={(e) => trackPageActivity("addressSecondaryNumberOnKeyPress", e)}
                                    data-neuro-label="addressSecondaryNumber"
                                />
                            </FormRow>
                            <FormRow>
                                <FormInput
                                    type="text"
                                    name="city"
                                    label="City"
                                    value={this.getEditValue("city")}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.city}
                                    turnOffAutoFill={true}
                                    onKeyUp={(e) => trackPageActivity("cityOnKeyPress", e)}
                                    data-neuro-label="city"
                                />
                            </FormRow>
                            <FormRow>
                                <FormInput
                                    type="text"
                                    name="stateCode"
                                    label="State"
                                    value={this.getEditValue("stateCode")}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.stateCode}
                                    turnOffAutoFill={true}
                                />
                            </FormRow>
                            <FormRow>
                                <FormInput
                                    type="text"
                                    min="0"
                                    max="99999"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    name="zip"
                                    label={this.state.checkingZipCode ? 'checking...' : "Zip code"}
                                    value={this.getEditValue("zip")}
                                    innerRef={this.zipInput}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.zip}
                                    turnOffAutoFill={true}
                                    onKeyUp={(e) => trackPageActivity("zipOnKeyPress", e)}
                                    data-neuro-label="zip"
                                />
                            </FormRow>
                            <FormRow>
                                <FormInput
                                    type="text"
                                    name="employer"
                                    label="Employer"
                                    value={this.getEditValue("employer")}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    errors={this.state.errors}
                                    fieldsError={this.props.fieldsError}
                                    skip={!this.props.fieldsRequired.employer}
                                />
                            </FormRow>
                            {!this.state.addressPage && (
                                <FormRow>
                                    {" "}
                                    <div className={styles.footer}>
                                        <div className="row">
                                            <div className="col-1" style={{minWidth: "40px"}}>
                                                <div
                                                    role="dialog"
                                                    data-test-id="reviewed"
                                                    className={styles.box}
                                                    onClick={() => {
                                                        const rev = !this.state.reviewed;
                                                        this.props.submitCheckboxSelection(this.props.loanAppId, "DATA_COLLECTION_PAGE", rev);
                                                        this.setState({reviewed: rev});
                                                    }
                                                    }
                                                >
                                                    {this.state.reviewed && (
                                                        <span
                                                            className="material-icons"
                                                            style={{
                                                                fontSize: "25px",
                                                                paddingLeft: "1px",
                                                                paddingTop: "1px"
                                                            }}
                                                        >
                              done
                            </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={classNames("col", styles.reviewed)}>
                                                I agree to the{" "}
                                                <span
                                                    data-toggle="modal"
                                                    data-target="#tos"
                                                    onClick={() => {
                                                        logAmplitudeEvent("Clicked Terms of Service Link", this.logProps);
                                                    }}
                                                >
                          Terms of Service
                        </span>
                                                {", "}
                                                <span
                                                    data-toggle="modal"
                                                    data-target="#eDisclouser"
                                                    onClick={() => {
                                                        logAmplitudeEvent("Clicked E-Sign Consent Link", this.logProps);
                                                    }}
                                                >
                          E-Sign Consent
                        </span>
                                                , and{" "}
                                                <span
                                                    data-toggle="modal"
                                                    data-target="#privacyPolicy"
                                                    onClick={() => {
                                                        logAmplitudeEvent("Clicked Privacy Policy Link", this.logProps);
                                                    }}
                                                >
                          Privacy Policy
                        </span>
                                                . I authorize Wisetack to obtain my credit report and
                                                verify my information.
                                            </div>
                                        </div>
                                    </div>
                                </FormRow>
                            )}
                        </Form>
                        <div className="row">
                            <div className="col-1">
                                <div className={styles.score}>
                  <span className={classNames("material-icons", styles.scoreIcon)}>
                    lock_outline
                  </span>
                                </div>
                            </div>
                            <div className="col">
                                <div className={styles.score}>
                      <span className={styles.scoreTxt}>
                      {this.state.addressPage ? "Applying " : "Continuing "}
                          <b>will not</b> impact your credit&nbsp;score.
                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="row"
                            style={{paddingLeft: "10px", paddingRight: "10px"}}
                        >
                            <div className="col">
                                <button
                                    data-test-id="continue"
                                    className={btnClasses}
                                    onClick={this.handleOnButtonClick}
                                    onKeyDown={this.handleOnEnterPress}
                                >
                                    {this.state.addressPage ? "APPLY" : "CONTINUE"}
                                </button>
                            </div>
                        </div>
                        <div style={{margin: "20px", textAlign: "center"}}>
              <span style={{fontSize: "10px"}}>
                  Wisetack loans are made by Hatch Bank.
              </span>
                        </div>
                        <ConsumerEntryPageMoreInfoModal/>
                        <TermsOfService/>
                        <PrivacyPolicyModal/>
                        <ElectronicDisclousersModal/>
                    </Fragment>
                ) : (
                    <div style={{paddingBottom: "15px"}}/>
                )}
            </Container>
        );
    }
}

ConsumerEntryPage.propTypes = {
    month: PropTypes.string,
    year: PropTypes.string,
    day: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    annualIncomeBeforeTaxes: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    zip: PropTypes.string,
    ssn4: PropTypes.string,
    ssn: PropTypes.string,
    streetAddress1: PropTypes.string,
    addressSecondaryNumber: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    employer: PropTypes.string,
    submitData: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    confirmed: PropTypes.bool
};

const setPropFromState = (props, state, name) => {
    if (name === "dob" && state[name]) {
        // 'YYYY-MM-DD'
        const parts = state[name].split("-");
        if (parts.length === 3) {
            props.year = parts[0];
            props.month = parts[1];
            props.day = parts[2];
        }
        return;
    }
    props[name] = state[name];
};

const loanProps = [
    "neuroIdSessionStarted",
    "loanAppId",
    "status",
    "token",
    "selectedLoanOfferStatus",
    "fieldsRequired",
    "fieldsError",
    "fieldsValue",
    "merchantName",
    "merchantId",
    "transactionAmount",
    "rejectReasonsList",
    "bankVerificationRequired",
    "moreInfoRequired",
    "autoPaymentsDecision",
    "balanceDecision",
    "declineReasonsList",
    "lockRequired",
    "offerLock",
    "cancelReasonsList",
    "profileFound",
    "prequalStatus",
    "suggestions",
    "refunded",
    "activeLoans",
    "payoutsList",
    "pinVerified"
];

const mapStateToProps = state => {
    let props = {initExpired: state.consumer.initExpired};
    loanProps.forEach(name => setPropFromState(props, state.consumer, name));
    formFields.forEach(name => setPropFromState(props, state.consumer, name));
    setPropFromState(props, state.consumer, "isLoading");
    setPropFromState(props, state.consumer, "expectedRunLength");
    setPropFromState(props, state.consumer, "errorMessage");
    setPropFromState(props, state.consumer, "checkboxes");
    setPropFromState(props, state.consumer, "emailVerificationRequired");

    props['neuroIdPiiPage'] =
        props.fieldsRequired.firstName ||
        props.fieldsRequired.lastName ||
        props.fieldsRequired.dob ||
        props.fieldsRequired.email ||
        props.fieldsRequired.ssn4 ||
        props.fieldsRequired.annualIncomeBeforeTaxes

    props['neuroIdAddressPage'] =
        props.fieldsRequired.streetAddress1 ||
        props.fieldsRequired.city ||
        props.fieldsRequired.zip

    return props;
};

export default connect(
    mapStateToProps,
    {getAddressSuggestions, getLoanStatus, submitData, submitCheckboxSelection, setError, neuroIdSetSessionStarted}
)(ConsumerEntryPage);

export {nextPageForStatus};