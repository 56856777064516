import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import {getCaptureContext, getZip, submitCardData} from "../../store/actions/consumerActions";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import classNames from "classnames";
import styles from "./CardCollectionPage.module.scss";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import Script from 'react-load-script'
import {BorrowerFieldValidator} from "@wisetack/shared-ui/utils/BorrowerFieldValidator";
import beep from "@wisetack/shared-ui/utils/Beep";
import {trackPageActivity} from "../../utils/addressPageActivity";
import FormCardExpirationDateInput from "@wisetack/shared-ui/components/FormCardExpirationDateInput";
import ErrorWithIcon from "@wisetack/shared-ui/components/ErrorWithIcon";
import FormMicroformInput from "@wisetack/shared-ui/components/FormMicroformInput";
import styled from 'styled-components'
import {CARD_COLLECTION_PAGE_NAME, TRY_AGAIN_ERROR_MESSAGE} from "./CardCollectionConstants";

const cybersourceMicroformUrl = "https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js";

const default_error_message = (expirationDate) => {
    return (
        <>
            Please double check your card number, zip code, and CVV.
            Alternatively, add a different debit card with sufficient funds by {expirationDate}.
            <br/><br/>
            Without this, we&apos;re unable to process your application.
        </>
    )
}

const StyledWrapper = styled.div`
  .form-row {
    .form-group {
      input {
        font-family: "Helvetica", "Arial", sans-serif;
      }
    }

    position: relative;
  }
`

class CardCollectionForm extends React.Component {

    state = {
        zipCode: "",
        month: "",
        year: "",
        expirationDate: "",
        checkedZip: true,
        checkingZipCode: false,
        validZipCode: false,
        cardNumberFieldFocused: false,
        cardNumberFieldFilled: false,
        securityCodeFieldFocused: false,
        securityCodeFieldFilled: false,
        isCardNumberValid: false,
        isSecurityCodeValid: false,
        twoDigitYear: true,
        microformError: null
    };

    microform = null;

    constructor(props) {
        super(props);
        this.validator = new BorrowerFieldValidator(CARD_COLLECTION_PAGE_NAME);
    }

    componentDidMount() {
        this.props.getCaptureContext(this.props.loanAppId);
    }

    onScriptError = () => {
        console.error('There was an issue loading the 0.11/flex-microform.min.js script');
    }

    onScriptLoad = () => this.initMicroform();


    setCardNumberFocus = (focused) => {
        this.setState({
            cardNumberFieldFocused: focused
        })
    }
    setCardNumberFilled = (filled) => {
        this.setState({
            cardNumberFieldFilled: filled
        })
    }
    setCardNumberValid = (valid) => {
        this.setState({
            isCardNumberValid: valid
        })
    }
    setSecurityCodeFocus = (focused) => {
        this.setState({
            securityCodeFieldFocused: focused
        })
    }
    setSecurityCodeFilled = (filled) => {
        this.setState({
            securityCodeFieldFilled: filled
        })
    }
    setSecurityCodeValid = (valid) => {
        this.setState({
            isSecurityCodeValid: valid
        })
    }


    initMicroform = () => {
        try {
            const microformStyles = {
                'input': {
                    'font-size': '18px',
                    'font-family': 'Montserrat, Helvetica, Arial, sans-serif',
                    'color': 'rgb(49, 54, 76)',
                    'font-weight': '400',
                },
                ':focus': {'color': 'rgb(49, 54, 76)'},
                ':disabled': {'cursor': 'not-allowed'},
                'valid': {'color': 'rgb(49, 54, 76)'},
                'invalid': {'color': 'rgb(49, 54, 76)'},
                '::placeholder': {
                    'font-size': '12.5px',
                    'color': 'rgb(111, 117, 149)',
                    'font-weight': '300'
                }
            };
            const flex = new window.Flex(this.props.captureContext);
            this.microform = flex.microform({styles: microformStyles});
            const cardNumber = this.microform.createField('number', {placeholder: ''});
            const securityCode = this.microform.createField('securityCode', {placeholder: ''});

            cardNumber.load('#card-number-container');
            securityCode.load('#security-code-container');

            cardNumber.on('focus', () => this.setCardNumberFocus(true));
            cardNumber.on('blur', () => this.setCardNumberFocus(false));
            cardNumber.on('change', (data) => {
                this.setCardNumberValid(data.valid);
                this.setCardNumberFilled(!data.empty);
            });

            securityCode.on('focus', () => this.setSecurityCodeFocus(true));
            securityCode.on('blur', () => this.setSecurityCodeFocus(false));
            securityCode.on('change', (data) => {
                this.setSecurityCodeValid(data.valid);
                this.setSecurityCodeFilled(!data.empty);
            });

        } catch (err) {
            console.error('Error creating Flex microform', err);
        }
    }

    isFormValid = () => {
        return this.microform && this.state.isSecurityCodeValid && this.state.isCardNumberValid && this.state.validZipCode && this.state.month.length === 2 && (this.state.twoDigitYear ? this.state.year.length === 2 : this.state.year.length === 4);
    }

    getEditValue = name => {
        if (this.state[name]) {
            return this.state[name];
        }
        return "";
    };

    setError(name, error) {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    validateIsRealZipcode = async (val) => {

        this.setState({
            checkingZipCode: true
        });

        const result = await getZip(val);
        const valid = !!result.state;

        this.setState({
            checkingZipCode: false,
            checkedZip: true,
            validZipCode: valid
        })

        if (!valid) {
            this.setError('zipCode', 'Invalid zip code');
            logAmplitudeEvent('Validation Error', {
                application: "Borrower",
                field: "Zip Code",
                value: val,
                message: "Invalid zip code",
                page: CARD_COLLECTION_PAGE_NAME
            });
        }
    };

    handleOnSubmitClick = () => {

        const year = parseInt(this.state.year);
        const expirationYear = this.state.twoDigitYear ? year + 2000 : year;

        const options = {
            expirationMonth: this.state.month,
            expirationYear: expirationYear.toString()
        };

        this.microform.createToken(options, (err, token) => {
            if (err) {
                console.error(err);
                this.setState({
                    microformError: err.reason
                });
            } else {
                this.setState({
                    microformError: null,
                    serverSideError: null
                });
                this.props.submitCardData(this.props.loanAppId, token, this.state.zipCode);
                this.clearForm();
            }
        });

    };

    clearForm = () => {
        this.setState({
            zipCode: "",
            month: "",
            year: "",
            expirationDate: "",
            checkedZip: true,
            validZipCode: false,
            cardNumberFieldFocused: false,
            cardNumberFieldFilled: false,
            securityCodeFieldFocused: false,
            securityCodeFieldFilled: false
        });
    }

    getCardCollectionError = () => {
        if (this.state.microformError) {
            return this.resolveCreateTokenErrorMessage(this.state.microformError);
        } else if (this.props.serverSideError) {
            return default_error_message(this.resolveLoanExpirationDate());
        }
        return null;
    }

    resolveCreateTokenErrorMessage = (createTokenErrorReason) => {
        if (["CREATE_TOKEN_VALIDATION_PARAMS", "CREATE_TOKEN_VALIDATION_FIELDS"].includes(createTokenErrorReason)) {
            return default_error_message(this.resolveLoanExpirationDate());
        }
        return TRY_AGAIN_ERROR_MESSAGE;
    }

    resolveLoanExpirationDate = () => {
        return this.props.loanAppExpirationDate ? this.props.loanAppExpirationDate : this.props.loanExpirationDate;
    }

    handleOnChange = e => {
        let val = e.target.value;
        let name = e.target.name;

        val = val.replace(/\D/g, "");

        if (name === "zipCode") {

            if (val.length > 5) {
                beep();
                return;
            } else if (val.length === 5) {
                // Async request; internally sets state.
                this.validateIsRealZipcode(val);
            } else {
                this.setError('zipCode', '');
                this.setState({
                    checkedZip: false,
                    validZipCode: false
                });
            }
        }

        this.setState({[name]: val});
    }

    render() {

        const {
            status,
            isLoading,
            captureContext
        } = this.props;


        const showContent = status && !isLoading && captureContext;

        const btnDisabled = !this.isFormValid();

        const submitBtnClasses = classNames({
            btn: true,
            "btn-block": true,
            "btn-disabled": btnDisabled,
            [styles.buttonDisabled]: btnDisabled,
            [styles.buttonEnabled]: !btnDisabled
        });

        return (<>
                {showContent &&
                    <Container>
                        <div style={{paddingBottom: "15px"}}>
                            <StyledWrapper>
                                <Form>
                                    <FormRow>
                                        <FormMicroformInput
                                            type="text"
                                            name="card-number-container"
                                            errors={this.state.errors}
                                            fieldsError={this.props.fieldsError}
                                            focused={this.state.cardNumberFieldFocused}
                                            filled={this.state.cardNumberFieldFilled}
                                            label={'Debit Card Number'}
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <FormCardExpirationDateInput
                                            name="expirationDate"
                                            label="Expiration date"
                                            month={this.getEditValue("month")}
                                            year={this.getEditValue("year")}
                                            twoDigitYear={this.state.twoDigitYear}
                                            onChange={this.handleOnChange}
                                            onFocus={() => {
                                                this.setError("expirationDate", "");
                                            }}
                                            onBlur={(evt) => {
                                                let monthValue = parseInt(evt.target.value, 10).toString();
                                                if (evt.target.name === "month" && monthValue >= 1 && monthValue < 10) {
                                                    monthValue = monthValue.padStart(2, '0');
                                                    this.setState({month: monthValue});
                                                }
                                                if (evt.target.name === "year") {
                                                    const error = this.validator.validateCardExpirationDate(this.getEditValue("month"), this.getEditValue("year"), this.state.twoDigitYear);
                                                    this.setError("expirationDate", error);
                                                }
                                            }}
                                            errors={this.state.errors}
                                            fieldsError={this.props.fieldsError}
                                        />
                                        <FormMicroformInput
                                            type="text"
                                            name="security-code-container"
                                            errors={this.state.errors}
                                            fieldsError={this.props.fieldsError}
                                            focused={this.state.securityCodeFieldFocused}
                                            filled={this.state.securityCodeFieldFilled}
                                            label={'CVC'}
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <FormInput
                                            type="text"
                                            min="0"
                                            max="99999"
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            name="zipCode"
                                            label={this.state.checkingZipCode ? 'checking...' : "Zip code"}
                                            value={this.getEditValue("zipCode")}
                                            innerRef={this.zipInput}
                                            onChange={this.handleOnChange}
                                            onBlur={(evt) => {
                                                const val = evt.target.value;
                                                if (!this.state.checkedZip) {
                                                    const error = this.validator.validateZipCode(val);
                                                    this.setError('zipCode', error);
                                                }
                                            }}
                                            errors={this.state.errors}
                                            fieldsError={this.props.fieldsError}
                                            turnOffAutoFill={true}
                                            onKeyUp={(e) => trackPageActivity("zipOnKeyPress", e)}
                                        />
                                    </FormRow>
                                </Form>
                            </StyledWrapper>
                            <br/>
                            <ErrorWithIcon
                                pageName={CARD_COLLECTION_PAGE_NAME}>{this.getCardCollectionError()}</ErrorWithIcon>
                            <br/>
                            <button
                                onClick={this.handleOnSubmitClick}
                                className={submitBtnClasses}
                                data-test-id="submit-button">SUBMIT
                            </button>
                        </div>
                        <Script
                            url={cybersourceMicroformUrl}
                            onLoad={this.onScriptLoad}
                            onError={this.onScriptError}/>
                    </Container>}
            </>
        );
    }
}

CardCollectionForm.propTypes = {
    getCaptureContext: PropTypes.func.isRequired,
    submitCardData: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => ({
    loanAppId: state.consumer.loanAppId,
    isLoading: state.consumer.isLoading,
    serverSideError: state.consumer.errorMessage,
    status: state.consumer.status,
    selectedLoanOfferStatus: state.consumer.selectedLoanOfferStatus,
    captureContext: state.consumer.captureContext,
    initExpired: state.consumer.initExpired,
    lockRequired: state.consumer.lockRequired,
    expectedRunLength: state.consumer.expectedRunLength,
    zipCode: state.zipCode,
    month: state.month,
    year: state.year,
    expirationDate: state.expirationDate,
    selectedPlanAmount: state.consumer.selectedPlan.amount,
    loanAppExpirationDate: state.consumer.loanAppExpirationDate,
    firstMonthPrepayment: state.consumer.firstMonthPrepayment
});

export default connect(mapStateToProps, {getCaptureContext, submitCardData})(
    CardCollectionForm
);
