import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Log from "@wisetack/shared-ui/utils/Log";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Plan from "@wisetack/shared-ui/components/Plan";
import PlanFmp from "@wisetack/shared-ui/components/PlanFmp";
import {acceptOffer, getOffers} from "../store/actions/consumerActions";
import {formatAmount} from "@wisetack/shared-ui/utils/format";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {nextPageForStatus} from "./ConsumerEntryPage.js";
import {LoaderWithMessage} from "../components/LoaderWithMessage";
import classNames from "classnames";
import styles from "./ConsumerChoosePlanPage.module.scss";
import Modal from "@wisetack/shared-ui/components/Modal";
import info from "@wisetack/assets/img/info-icon.svg";

const pageName = "Offers Page";

class ConsumerChoosePlanPage extends React.Component {

    isPayoutsListFound() {
        return this.props.payouts && this.props.payouts.length > 1
    }

    isOnePayoutFound() {
        return this.props.payouts && this.props.payouts.length === 1
    }

    navigateToPayouts() {
        if (this.isPayoutsListFound()) {
            for (const payout of this.props.payouts) {
                // if one of the payouts approved (it means other offer can't be selected)
                // navigate to the payouts list
                if (['DOCUMENTS_SIGNED', 'OFFER_CONVERTED', 'SETTLED'].includes(payout.status)) {
                    this.props.history.push("/loan_payouts_return");
                    return true;
                }
            }
        } else if (this.isOnePayoutFound()) {
            const payout = this.props.payouts[0];
            if (payout.status === 'DECLINED' && payout.path) {
                this.props.history.push(payout.path);
                return true;
            }
        }
        return false;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.logProps = {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: pageName,
        };
        logAmplitudeEvent(pageName, this.logProps);
        this.props.getOffers(this.props.loanAppId);
    }

    componentDidUpdate(prevProps) {

        if (this.props.initExpired) {
            Log.info(this.props.initExpired, `initExpired`);
            this.props.history.push("/expired");
            return;
        }
        if (this.props.errorMessage) {
            Log.info(this.props.errorMessage, `error`);
            this.props.history.push("/error");
            return;
        }
        if (this.navigateToPayouts()) {
            return;
        }
        if (this.props.offerAcceptedAt !== prevProps.offerAcceptedAt) {
            // add logic here to route to another page depending from status
            switch (this.props.selectedLoanOfferStatus) {
                case "SELECTED":
                    const lockRequired = this.props.lockRequired;
                    if (lockRequired) {
                        this.props.history.push("/offer_lock");
                    } else if (this.props.status === "CONDITIONAL_APPROVAL" && this.props.firstMonthPrepayment) {
                        this.props.history.push("/card_collection");
                    } else if (this.props.status === "OFFER_AVAILABLE" || this.props.status === "CONDITIONAL_APPROVAL" || this.props.status === "CONDITIONAL_APPROVAL_DEBIT_ADDED") {
            if (this.isOnePayoutFound() && false) {
              //This is done intentionally. This feature should be disabled
              this.props.history.push("/review_plan")
            } else {
                        this.props.history.push("/link_bank");
            }
                    } else { //when reentering from same flow.
                        // Use same logic as entryPage. Be careful as it stays in choose_plan
                        let page = nextPageForStatus(this.props);
                        if (page[0]) {
                            this.props.history.push(page[0]);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }

    handleOnPlanClick = (plan) => {
        Log.info(plan, `Plan clicked`);
        logAmplitudeEvent("Offer Selected", {
            ...this.logProps,
            offersCount: this.props.plans.length,
            offerSelectedDuration: plan.months,
            selectedInterestFreeOffer: plan.apr === 0,
            partner: plan.partner
        });
        this.props.acceptOffer(this.props.loanAppId, plan.id, plan.partner);
    };

    render() {
        const {
            status,
            isLoading,
            errorMessage,
            plans,
            expectedRunLength,
            firstMonthPrepayment,
        } = this.props;

        let approvedAmount;
        if (plans) {
            approvedAmount = plans.map(p => p.approvedAmount).find(a => a != null);
        }

        const showContent = approvedAmount && status && !isLoading && !errorMessage;

        return (
            <Container>
                <PageHeader progress="42%">
                    {showContent ? (
                        <Fragment>
                            <div>
                                You’re{" "}
                                {status === "CONDITIONAL_APPROVAL" ? "qualified" : "approved"}{" "}
                                for {formatAmount(approvedAmount)}!
                            </div>
                            <div>
                                {status === "CONDITIONAL_APPROVAL" && (
                                    <>
                                        Your application isn't complete yet. <br/>
                                    </>
                                )}
                                {this.isPayoutsListFound() ? (
                                        <div>Choose an option.<sup style={{fontSize: 12}}>&#x2020;</sup></div>) :
                                    <div>Choose an option.</div>
                                }
                                <span data-toggle="modal"
                                       data-target="#firstPaymentInfoModal"
                                       onClick={() => {
                                         logAmplitudeEvent("Clicked First Payment Info of Consumer Choose Plan Page", this.logProps);
                                       }}
                                       className={classNames("material-icons", firstMonthPrepayment ? styles.infoIcon : styles.hideInfoIcon)}>info_outline</span>
                                <Modal id={'firstPaymentInfoModal'}>
                                  <div className={styles.modal_text}>
                                    <img className={styles.logo} src={info} alt="info.svg"/>
                                    <div>
                                      The amount you are qualified for includes the first payment due at loan start.
                                    </div>
                                  </div>
                                </Modal>
                            </div>
                        </Fragment>
                    ) : null}
                    {showContent ? (
                        <div style={{textAlign: "center"}}>
                            No origination, prepayment, or late fees.
                        </div>
                    ) : null}
                    <></>
                </PageHeader>
                <LoaderWithMessage isLoading={isLoading} duration={expectedRunLength}/>
                <Error pageName={pageName}>{errorMessage}</Error>
                <div style={{paddingBottom: "15px"}}>
                    {showContent && !firstMonthPrepayment &&
                        plans.map((item) => (
                            <Plan
                                key={item.id}
                                item={item}
                                onClick={() => this.handleOnPlanClick(item)}
                            />
                        ))}
                    {showContent && firstMonthPrepayment &&
                        plans.map((item) => (
                            <PlanFmp
                                key={item.id}
                                item={item}
                                onClick={() => this.handleOnPlanClick(item)}
                            />
                        ))}
                </div>
                <div>
                    {this.isPayoutsListFound() && showContent ? (
                            <div style={{textAlign: "center", fontSize: 13}}><sup>&#x2020;</sup>
                                If you are paying the merchant in stages,
                                your monthly payments will vary depending on the amount disbursed and time of release.
                            </div>) :
                        <div></div>}
                </div>
            </Container>
        );
    }
}

ConsumerChoosePlanPage.propTypes = {
    getOffers: PropTypes.func.isRequired,
    acceptOffer: PropTypes.func.isRequired,
    plans: PropTypes.array.isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => ({
    loanAppId: state.consumer.loanAppId,
    merchantName: state.consumer.merchantName,
    isLoading: state.consumer.isLoading,
    errorMessage: state.consumer.errorMessage,
    status: state.consumer.status,
    selectedLoanOfferStatus: state.consumer.selectedLoanOfferStatus,
    offerAcceptedAt: state.consumer.offerAcceptedAt,
    plans: state.consumer.plans,
    payouts: state.consumer.payouts,
    transactionAmount: state.consumer.transactionAmount,
    initExpired: state.consumer.initExpired,
    lockRequired: state.consumer.lockRequired,
    autoPaymentsDecision: state.consumer.autoPaymentsDecision,
    expectedRunLength: state.consumer.expectedRunLength,
    firstMonthPrepayment: state.consumer.firstMonthPrepayment
});

export default connect(mapStateToProps, {getOffers, acceptOffer})(
    ConsumerChoosePlanPage
);
