import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Log from "@wisetack/shared-ui/utils/Log";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {getLoanStatus, sessionExpired} from "../../store/actions/consumerActions";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {LoaderWithMessage} from "../../components/LoaderWithMessage";
import classNames from "classnames";
import styles from "./CardCollectionPage.module.scss";
import CardCollectionLearnMoreModal from "./CardCollectionLearnMoreModal";
import {nextPageForStatus} from "../ConsumerEntryPage";
import Ticker from "@wisetack/shared-ui/components/Ticker";
import CardCollectionForm from "./CardCollectionForm";
import {
    ABSOLUTE_TIMEOUT,
    CARD_COLLECTED_MESSAGE,
    CARD_COLLECTION_BROADCAST_CHANNEL,
    CARD_COLLECTION_PAGE_NAME,
    CLOSE_POPUPS_MESSAGE,
    FMP_ON_ANSWER_MESSAGE,
    FMP_ON_QUESTION_MESSAGE,
    POPUP_OPENED_MESSAGE
} from "./CardCollectionConstants";
import {loadToken} from "@wisetack/shared-ui/utils/localStorage";


const firstMonthPaymentBoxClasses = classNames({
    [styles.firstMonthPayment]: true,
    "row": true
});

const firstMonthPaymentTitleClasses = classNames({
    [styles.title]: true,
    "col-8": true
});

const firstMonthPaymentAmountClasses = classNames({
    [styles.title]: true,
    "col-4": true
});

const addDebitCardBtnClasses = classNames({
    btn: true,
    "btn-block": true,
    "btn-disabled": false,
    [styles.buttonDisabled]: false,
    [styles.buttonEnabled]: true
});


class CardCollectionPage extends React.Component {

    broadcastChannel;

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.logProps = {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: CARD_COLLECTION_PAGE_NAME,
        };
        logAmplitudeEvent(CARD_COLLECTION_PAGE_NAME, this.logProps);

        if (this.isInIframe()) {
            this.setUpBroadcastChannel();
        }

        this.setState({addDebitCardBtnDisabled: false});

    }

    componentDidUpdate = (prevProps) => {

        if (!this.props.firstMonthPrepayment) {
            this.handleIncorrectEntry();
        } else if (this.props.initExpired) {
            Log.info(this.props.initExpired, `initExpired`);
            this.props.history.push("/expired");
        } else if (this.props.status === "CONDITIONAL_APPROVAL_DEBIT_ADDED") {
            this.closeChannel();
            this.props.history.push("/link_bank");
        } else if (prevProps.status === "CONDITIONAL_APPROVAL" && this.props.status === "OFFER_AVAILABLE") {
            this.props.history.push("/link_bank")
        } else if (this.props.status === "CONDITIONAL_APPROVAL") {
            //stay here!
        } else {
            this.handleIncorrectEntry();
        }

    }

    handleIncorrectEntry = () => {
        let page = nextPageForStatus(this.props);
        if (page[0]) {
            this.props.history.push(page[0]);
        } else {
            this.props.history.push("/error");
        }
    }

    setUpBroadcastChannel = () => {

        this.broadcastChannel = new BroadcastChannel(CARD_COLLECTION_BROADCAST_CHANNEL);

        window.addEventListener("beforeunload", () => {
            this.broadcastChannel.postMessage(CLOSE_POPUPS_MESSAGE);
        });

        this.broadcastChannel.onmessage = (event) => {
            if (event.data === POPUP_OPENED_MESSAGE) {
                this.setState({addDebitCardBtnDisabled: true});
            } else if (event.data === CARD_COLLECTED_MESSAGE) {
                this.props.getLoanStatus(loadToken());
            } else if (event.data === FMP_ON_QUESTION_MESSAGE) {
                this.broadcastChannel.postMessage({
                    name: FMP_ON_ANSWER_MESSAGE,
                    loanAppExpirationDate: this.props.loanAppExpirationDate
                });
            }
        };
    }

    closeChannel = () => {
        if (this.broadcastChannel) {
            this.broadcastChannel.close();
        }
    }

    handleOnTicker = () => {
        const session = sessionStorage.getItem('wisetack:captureContextSession');
        const sessionData = session ? session.split(':') : null;
        if (sessionData && sessionData.length === 2) {
            if (this.props.loanAppId && sessionData[0] === this.props.loanAppId.substr(0, 8)) {
                const sessionExpiration = Number(sessionData[1]) + ABSOLUTE_TIMEOUT;
                if (sessionExpiration < Date.now()) {
                    this.handleOnAbsoluteExpiration();
                }
            }
        }
    }

    handleOnAbsoluteExpiration = () => {
        if (!this.props.location.pathname.match(/session_.*_expiration/)) {
            logAmplitudeEvent('Session Absolute Expiration', {loanAppId: this.props.loanAppId})
            this.props.sessionExpired();
            this.props.history.replace('/session_absolute_expiration');
        }
    }

    isInIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    handleOnAddDebitCardClick = () => {
        window.open('/#/card_collection_popup', '_blank', 'noopener');
    }

    render = () => {

        const {
            status,
            isLoading,
            expectedRunLength
        } = this.props;


        const showContent = status && !isLoading;


        return (
            <>
                <Ticker timeout={10000} callback={this.handleOnTicker} enabled={true}/>
                <Container>
                    <CardCollectionLearnMoreModal loanExpirationDate={this.props.loanAppExpirationDate}/>
                    <PageHeader progress="40%">
                        {showContent &&
                            <Fragment>
                                <div>
                                    Add a debit card
                                </div>
                            </Fragment>
                        }
                        {showContent &&
                            <div className={styles.content} style={{textAlign: "center"}}>
                                <p>
                                    In order to process your application, we will
                                    <br/>need a debit card on file by {this.props.loanAppExpirationDate}.
                                    <br/>Otherwise, we are unable to process
                                    <br/>your application.
                                    <br/>
                                    <br/>We will charge the amount below when the
                                    <br/>loan starts, and you may see a pending
                                    <br/>charge today.
                                    <br/><span
                                    data-toggle="modal"
                                    data-target="#consumerCardCollectionLearnMoreModal"
                                    onClick={() => {
                                        logAmplitudeEvent("Opened Card Collection Learn More Modal", this.logProps);
                                    }}
                                    style={{display: "inline-block"}}>
                                        &nbsp;
                                    Learn more
                                    </span>
                                </p>

                            </div>
                        }
                    </PageHeader>
                    {showContent &&
                        <div className={firstMonthPaymentBoxClasses}>
                            <div className={firstMonthPaymentTitleClasses} style={{textAlign: "left"}}>
                                FIRST PAYMENT:
                            </div>
                            <div className={firstMonthPaymentAmountClasses}
                                 style={{textAlign: "right"}}>${this.props.selectedPlanAmount.toFixed(2)}</div>
                        </div>
                    }
                    <LoaderWithMessage isLoading={isLoading} duration={expectedRunLength}/>
                    {this.isInIframe() ? (
                        <>
                            <br/>
                            <div className={styles.content} style={{textAlign: "center"}}>
                                <p>
                                    A new window will pop up to collect your
                                    <br/>card details. Please allow popups through your
                                    <br/>browser.
                                </p>
                            </div>
                            <button
                                disabled={this.state && this.state.addDebitCardBtnDisabled}
                                onClick={this.handleOnAddDebitCardClick}
                                className={addDebitCardBtnClasses}
                                data-test-id="submit-button">ADD DEBIT CARD
                            </button>
                        </>
                    ) : <CardCollectionForm/>}
                </Container>
            </>
        );
    }
}

CardCollectionPage.propTypes = {
    getLoanStatus: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => ({
    loanAppId: state.consumer.loanAppId,
    isLoading: state.consumer.isLoading,
    serverSideError: state.consumer.errorMessage,
    status: state.consumer.status,
    token: state.consumer.token,
    selectedLoanOfferStatus: state.consumer.selectedLoanOfferStatus,
    initExpired: state.consumer.initExpired,
    lockRequired: state.consumer.lockRequired,
    expectedRunLength: state.consumer.expectedRunLength,
    selectedPlanAmount: state.consumer.selectedPlan.amount,
    loanAppExpirationDate: state.consumer.loanAppExpirationDate,
    firstMonthPrepayment: state.consumer.firstMonthPrepayment
});

export default connect(mapStateToProps, {sessionExpired, getLoanStatus})(
    CardCollectionPage
);
