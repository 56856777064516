import _slicedToArray from "/home/carlos.matus/wisetack/clients/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React from "react";
import uuid from "uuid";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
export default function useApiRequest(stateName, parentRequestId) {
  var history = useHistory();
  var apiRequests = useSelector(function (state) {
    return state[stateName].apiRequests;
  });
  var apiError = useSelector(function (state) {
    return state[stateName].apiError;
  });

  var _React$useState = React.useState(parentRequestId || ''),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      requestId = _React$useState2[0],
      setRequestId = _React$useState2[1];

  var requestInProgress = !!requestId && !!apiRequests[requestId];

  function newRequest() {
    var requestId = uuid.v4();
    setRequestId(requestId);
    return requestId;
  }

  var requestError = formatErrorMessage(apiError, requestId);

  if (requestError && stateName === 'consumer' && (requestError.includes('Token has expired') || requestError === 'Unauthorized.')) {
    history.replace('/session_inactivity_expiration');
  }

  return [requestId, requestInProgress, requestError, newRequest];
}

var formatErrorMessage = function formatErrorMessage(apiError, requestId) {
  var errorMessage = '';

  if (apiError && apiError.requestId === requestId) {
    var error = apiError.error;

    if (error.message) {
      errorMessage = error.message;
    }

    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }
  }

  return errorMessage;
};